import { Typography, makeStyles } from "@material-ui/core";
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from "@material-ui/lab";
import clsx from "clsx";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { PlusIcon } from "../../icons/PlusIcon";
import { TimesCircleIcon } from "../../icons/TimesCircleIcon";
import { Button } from "../Button";
import { IconButton } from "../IconButton";
import { renderAutocompleteInput, useAutocompleteStyles } from "./common";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  base: {
    display: "flex",
    border: "solid 1px",
    borderColor: theme.palette.grey[300],
    borderRadius: "20px",
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[100],
    alignItems: "center",
    justifyContent: "space-between",
    padding: "9px",
    paddingLeft: "16px",
    height: "40px",
    "& + &": {
      marginTop: "5px",
    },
  },
  text: {
    color: theme.palette.text.primary,
  },
  button: {
    margin: "8px 0px",
    fontSize: "16px",
    width: "100%",
    borderRadius: "120px",
  },
}));

export interface AutocompleteMultipleProps<T>
  extends Omit<MuiAutocompleteProps<T, true, true, false>, "renderInput"> {
  error?: boolean;
}

export function AutocompleteMultiple<T>({
  getOptionLabel,
  value,
  onChange,
  options,
  error,
  ...props
}: AutocompleteMultipleProps<T>) {
  const classes = useStyles();

  const autocompleteRef = useRef<any>();
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);

  const handleDelete = useCallback(
    (e, option) => {
      onChange?.(e, value?.filter((v) => v !== option) || [], "remove-option");
    },
    [onChange, value]
  );

  useEffect(() => {
    const strictValue = value?.filter(
      (selected) => options.indexOf(selected) !== -1
    );
    if (strictValue && !_.isEqual(value?.length, strictValue?.length)) {
      onChange?.({} as any, strictValue, "remove-option");
    }
  }, [value, options, onChange]);

  const allOptionsSelected =
    options.length > 0 && options.length === (value?.length || 0);
  return (
    <React.Fragment>
      {value?.map((option, i) => (
        <Chip
          className={props.className}
          key={i}
          option={option}
          onDelete={(e) => handleDelete(e, option)}
          getLabel={getOptionLabel}
        />
      ))}
      <MuiAutocomplete
        ref={autocompleteRef}
        classes={useAutocompleteStyles()}
        renderInput={(params) => {
          const hasAnyValue = (value?.length || 0) > 0;
          const showInput = !hasAnyValue || focused;
          const showAddButton = !showInput && !allOptionsSelected;
          return (
            <React.Fragment>
              {renderAutocompleteInput({
                open,
                setOpen,
                focused,
                hidden: !showInput,
                error,
                ...params,
              })}
              {showAddButton && (
                <Button
                  className={classes.button}
                  size="small"
                  disableRipple
                  startIcon={<PlusIcon />}
                  onClick={() => {
                    setFocused(true);
                    setImmediate(() =>
                      autocompleteRef.current.querySelector("input").focus()
                    );
                  }}
                  color="primary"
                  disabled={allOptionsSelected}
                >
                  <Typography variant="body1">Add</Typography>
                </Button>
              )}
            </React.Fragment>
          );
        }}
        value={value}
        onChange={onChange}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        blurOnSelect
        openOnFocus
        multiple
        getOptionLabel={getOptionLabel}
        options={options}
        {...props}
      />
    </React.Fragment>
  );
}

function Chip<T>({
  className,
  option,
  getLabel,
  onDelete,
}: {
  className?: string;
  option: T;
  getLabel?: (option: T) => string;
  onDelete?: React.ReactEventHandler<{}>;
}) {
  const classes = useStyles();

  return (
    <ChipBase className={className}>
      <Typography className={classes.text} variant="body1">
        {getLabel?.(option) || option}
      </Typography>
      <IconButton size="small" onClick={(e) => onDelete?.(e)} edge="end">
        <TimesCircleIcon />
      </IconButton>
    </ChipBase>
  );
}

const ChipBase = ({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) => {
  const classes = useStyles();

  return <div className={clsx(classes.base, className)}>{children}</div>;
};
