import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  UseAutocompleteProps as MuiUseAutocompleteProps,
} from "@material-ui/lab";
import React, { useState } from "react";

import { renderAutocompleteInput, useAutocompleteStyles } from "./common";

export interface AutocompleteSingleProps<T>
  extends Omit<MuiAutocompleteProps<T, false, false, false>, "renderInput">,
    MuiUseAutocompleteProps<T, false, false, false> {
  error?: boolean;
}

export function AutocompleteSingle<T>({
  error,
  value,
  ...props
}: AutocompleteSingleProps<T>) {
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);

  return (
    <MuiAutocomplete
      classes={useAutocompleteStyles()}
      renderInput={(params) =>
        renderAutocompleteInput({ open, setOpen, focused, error, ...params })
      }
      value={value}
      multiple={false}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      {...props}
    />
  );
}
