import ApolloClient, {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-boost";
import retry from "promise-retry";

import schemaFragmentTypes from "../../__generated__/fragmentTypes.json";
import { AuthService } from "../../components/Auth";

export interface GraphQlClientOptions {
  authService: AuthService;
}

export class GraphQlClient {
  private readonly cache: InMemoryCache;
  private readonly apolloClient: ApolloClient<InMemoryCache>;

  constructor({ authService }: GraphQlClientOptions) {
    this.cache = new InMemoryCache({
      fragmentMatcher: new IntrospectionFragmentMatcher({
        introspectionQueryResultData: schemaFragmentTypes,
      }),
    });
    this.apolloClient = new ApolloClient({
      uri: "/api/graphql",
      cache: this.cache,
      request: (operation) => authService.addTokensToRequest(operation),
      onError: (error) => {
        authService.handleAnyAuthenticationErrors(error);
      },
    });
    authService.onSignIn(() => this.resetStore());
    authService.onSignOut(() => this.resetStore());
  }

  getApolloClient(): ApolloClient<InMemoryCache> {
    return this.apolloClient;
  }

  private async resetStore() {
    await retry(
      (tryAgain) =>
        this.apolloClient.resetStore().catch((error) => tryAgain(error)),
      {
        minTimeout: 100,
        maxTimeout: 200,
        maxRetryTime: 3000,
      }
    );
  }
}
