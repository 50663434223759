import {
  Fade,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import { webTheme } from "../../constants";
import { TimesCircleIcon } from "../../icons/TimesCircleIcon";
import { Button } from "../Button";
import { Link } from "../Link";

const GlobalBlurTransition = withStyles({
  "@global": {
    "#root": {
      transitionProperty: "filter",
      transitionDuration: webTheme.transitions.duration.short + "ms",
      transitionTimingFunction: webTheme.transitions.easing.easeInOut,
    },
  },
})(() => null);

const GlobalBlur = withStyles({
  "@global": {
    "#root": {
      filter: "blur(10px) grayscale(100%)",
    },
  },
})(() => null);

const useStyles = makeStyles((theme) => ({
  root: {
    background: "rgba(255, 255, 255, 0.7)",
  },
  paper: {
    borderRadius: "28px",
    overflowY: "visible",
    marginTop: "44px",
  },
  paperElevation24: {
    boxShadow: "0px 6px 24px rgba(0, 0, 0, 0.08)",
  },
  content: {
    padding: "10px",
  },
  exitButtonContainer: {
    marginTop: "-20px",
    height: "15px",
    position: "relative",
  },
  exitButton: {
    position: "absolute",
    right: "0px",
    bottom: "0px",
    marginRight: "-16px",
  },
  confirmPrompt: {
    position: "absolute",
    top: "0px",
    height: "100%",
    width: "100%",
    borderRadius: "28px",
    padding: "15% 100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  confirmPromptButtons: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "space-between",
  },
  confirmPromptButton: {
    width: "175px",
    margin: "8px",
    border: `2px solid ${theme.palette.primary.contrastText}`,
  },
  cancelButton: {
    color: `${theme.palette.primary.main} !important`,
    backgroundColor: `${theme.palette.primary.contrastText} !important`,
  },
  confirmButton: {
    color: `${theme.palette.primary.contrastText} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
}));

export interface DialogProps extends MuiDialogProps {
  disableExitButton?: boolean;
  confirmBeforeClose?: boolean;
}

export const Dialog = ({
  open,
  onClose,
  disableExitButton = false,
  confirmBeforeClose = false,
  children,
  ...props
}: DialogProps) => {
  const classes = useStyles();

  const [confirmingClose, setConfirmingClose] = useState<boolean>(false);

  useEffect(() => {
    if (!open) {
      setConfirmingClose(false);
    }
  }, [open, setConfirmingClose]);

  const handleUnload = useCallback(
    (event) => {
      if (confirmBeforeClose && open) {
        event.preventDefault();
        event.returnValue = "Are you sure you want to exit?";
      } else {
        delete event["returnValue"];
      }
    },
    [confirmBeforeClose, open]
  );

  const handleClose = useCallback(
    (event, reason) => {
      if (reason === "backdropClick" && shouldIgnoreClick(event)) {
        return;
      }
      if (confirmBeforeClose) {
        setConfirmingClose(true);
      } else {
        onClose?.(event, reason);
      }
    },
    [confirmBeforeClose, setConfirmingClose, onClose]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [handleUnload]);

  return (
    <React.Fragment>
      <GlobalBlurTransition />
      {open && <GlobalBlur />}
      <MuiDialog
        classes={{ paper: classes.paper }}
        BackdropProps={{ classes: { root: classes.root } }}
        PaperProps={{
          ...props.PaperProps,
          classes: {
            ...props.PaperProps?.classes,
            elevation24: classes.paperElevation24,
          },
        }}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        {...props}
      >
        <ExitButton
          disableExitButton={disableExitButton}
          onClose={handleClose}
        />
        {children}
        <ConfirmClosePrompt
          open={confirmingClose}
          onConfirm={() => onClose?.({}, "escapeKeyDown")}
          onCancel={() => setConfirmingClose(false)}
        />
      </MuiDialog>
    </React.Fragment>
  );
};

const ExitButton = ({
  disableExitButton,
  onClose,
}: {
  disableExitButton: boolean;
  onClose: (event: any, reason: "escapeKeyDown") => void;
}) => {
  const classes = useStyles();
  if (disableExitButton) {
    return null;
  }
  return (
    <div className={classes.exitButtonContainer}>
      <Button
        className={classes.exitButton}
        onClick={(event) => onClose(event, "escapeKeyDown")}
        variant="text"
        color="primary"
        size="small"
        endIcon={<TimesCircleIcon />}
      >
        Exit
      </Button>
    </div>
  );
};

const shouldIgnoreClick = (event: any) => {
  const clickX = _.get(event, "clientX");
  const isNearScrollBar = clickX && clickX > window.innerWidth - 30;
  return isNearScrollBar;
};

const ConfirmClosePrompt = ({
  open,
  onConfirm,
  onCancel,
}: {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  const classes = useStyles();

  return (
    <Fade in={open}>
      <div className={classes.confirmPrompt}>
        <Typography variant="h2" gutterBottom>
          Are you sure you want to exit?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Need help?{" "}
          <Link
            href="http://comealiveusa.com/contact-us"
            target="_blank"
            color="inherit"
          >
            Contact us.
          </Link>
        </Typography>
        <div className={classes.confirmPromptButtons}>
          <Button
            className={clsx(classes.confirmPromptButton, classes.cancelButton)}
            color="primary"
            onClick={() => onCancel()}
          >
            Continue
          </Button>
          <Button
            className={clsx(classes.confirmPromptButton, classes.confirmButton)}
            color="primary"
            onClick={() => onConfirm()}
          >
            Exit
          </Button>
        </div>
      </div>
    </Fade>
  );
};
