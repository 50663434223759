import { makeStyles } from "@material-ui/core";
import React from "react";

import { Icon, IconProps } from "./Icon";

const useStyles = makeStyles((theme) => ({
  up: {
    transform: "rotate(0deg)",
  },
  right: {
    transform: "rotate(90deg)",
  },
  down: {
    transform: "rotate(180deg)",
  },
  left: {
    transform: "rotate(270deg)",
  },
}));

export interface ChevronIconProps extends IconProps {
  direction: "up" | "right" | "down" | "left";
}

export const ChevronIcon = ({ direction, ...props }: ChevronIconProps) => {
  const classes = useStyles();

  return (
    <Icon className={classes[direction]} viewBox="0 0 448 512" {...props}>
      <path d="M6.101 359.293L25.9 379.092c4.686 4.686 12.284 4.686 16.971 0L224 198.393l181.13 180.698c4.686 4.686 12.284 4.686 16.971 0l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L232.485 132.908c-4.686-4.686-12.284-4.686-16.971 0L6.101 342.322c-4.687 4.687-4.687 12.285 0 16.971z" />{" "}
    </Icon>
  );
};
