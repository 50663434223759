import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px 0px 15px",
  },
}));

export interface DialogContentSectionProps
  extends React.HTMLAttributes<HTMLDivElement> {}

export const DialogContentSection = ({
  className,
  ...props
}: DialogContentSectionProps) => {
  const classes = useStyles();

  return <div className={clsx(classes.root, className)} {...props} />;
};
