import { ThemeOptions, createMuiTheme } from "@material-ui/core";

const options: ThemeOptions = {
  typography: {
    fontFamily: "Inter,sans-serif",
    fontSize: 16,
    h1: {
      fontFamily: "Playfair Display,serif",
      fontSize: 60,
      fontWeight: "bold",
      fontKerning: "normal",
    },
    h2: {
      fontFamily: "Playfair Display,serif",
      fontSize: 48,
      fontWeight: 700,
      fontKerning: "normal",
    },
    h3: {
      fontFamily: "Playfair Display,serif",
      fontSize: 40,
      fontWeight: "normal",
      fontKerning: "normal",
    },
    h4: {
      fontFamily: "Playfair Display,serif",
      fontSize: 21,
      color: "#B7B7B7",
      fontWeight: "normal",
      fontKerning: "normal",
    },
    body1: {
      fontFamily: "Inter,sans-serif",
      fontSize: 16,
      fontWeight: "normal",
      fontKerning: "normal",
      letterSpacing: "0",
    },
    body2: {
      fontFamily: "Inter,sans-serif",
      fontSize: 15,
      fontWeight: 300,
      fontKerning: "normal",
      letterSpacing: "0",
    },
    subtitle1: {
      fontFamily: "Inter,sans-serif",
      fontSize: 16,
      fontWeight: 300,
      color: "#BDBDBD",
      fontKerning: "normal",
      letterSpacing: "0",
      lineHeight: "1.4",
    },
    subtitle2: {
      fontFamily: "Inter,sans-serif",
      fontSize: 11,
      fontWeight: "normal",
      color: "#BABABA",
      fontKerning: "normal",
      letterSpacing: "0",
    },
    button: {
      fontFamily: "Inter,sans-serif",
      fontSize: 20,
      fontWeight: "normal",
      textTransform: "none",
      fontKerning: "normal",
      letterSpacing: "0",
    },
    caption: {
      fontFamily: "Playfair Display,serif",
      fontSize: 16,
      fontWeight: "normal",
      color: "#BABABA",
      fontKerning: "normal",
      letterSpacing: "0",
    },
  },
  palette: {
    primary: {
      dark: "#F2994A",
      main: "#F2994A",
      light: "#FEC047",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#5D66D3",
    },
    background: {
      default: "#FFFFFF",
    },
    info: {
      main: "#BABABA",
    },
    action: {
      active: "#BDBDBD",
      disabled: "#FFFFFF",
      disabledBackground: "#E0E0E0",
    },
    error: {
      main: "#EB5757",
    },
    success: {
      light: "#DEF2E7",
      main: "#4CAF50",
      dark: "#27AE60",
    },
    text: {
      primary: "#4F4F4F",
    },
    common: {
      white: "#FFFFFF",
    },
    grey: {
      "100": "rgba(196, 196, 196, 0.03)",
      "200": "rgba(196, 196, 196, 0.09)",
      "300": "rgba(0, 0, 0, 0.03)",
      "400": "rgba(0, 0, 0, 0.1)",
      "500": "#F2F2F2",
      "600": "#ACACAC",
      "700": "#BDBDBD",
    },
  },
  shape: {
    borderRadius: 12,
  },
  transitions: {
    duration: {
      enteringScreen: 500,
      leavingScreen: 250,
    },
  },
};

export const webTheme = createMuiTheme(options);

export const emailTheme = createMuiTheme(options, {
  typography: {
    body1: {
      fontFamily: "Playfair Display,serif",
    },
    body2: {
      fontFamily: "Playfair Display,serif",
    },
  },
});
