import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useOverrideStyles = makeStyles((theme) => ({
  root: {
    padding: "4px 16px",
    borderRadius: "10px",
    transition: theme.transitions.create(["transform", "box-shadow"], {
      duration: theme.transitions.duration.shorter,
    }),
    "&:hover": {
      transform: "scale3d(1.07, 1.07, 1)",
    },
  },
  sizeLarge: {
    borderRadius: "14px",
    fontWeight: 900,
    fontSize: "24px",
  },
  iconSizeMedium: {
    "& > *:first-child": {
      fontSize: "16px",
    },
  },
  sizeSmall: {
    borderRadius: "6px",
    borderWidth: "2px",
    fontWeight: 600,
    fontSize: "14px",
  },
  iconSizeSmall: {
    "& > *:first-child": {
      fontSize: "18px",
    },
  },
  contained: {
    boxShadow: "0px 9px 24px rgba(201, 201, 201, 0.8)",
    "&:hover": {
      boxShadow: "0px 10px 28px rgba(201, 201, 201, 0.8)",
    },
  },
  disableElevation: {
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  pulsate: {
    animation: `$button-pulsate 3500ms ${theme.transitions.easing.easeInOut} 1000ms infinite`,
  },
  primaryAction: {
    fontFamily: "Playfair Display,serif",
  },
  "@keyframes button-pulsate": {
    "0%": {
      boxShadow: `0px 0px 0px rgba(242, 153, 74, 0.1)`,
    },
    "100%": {
      boxShadow: `0px 0px 0 30px rgba(242, 153, 74, 0.1)`,
    },
  },
}));

export interface ButtonProps extends MuiButtonProps {
  elevated?: boolean;
  pulsate?: boolean;
  primaryAction?: boolean;
  component?: string;
}

export const Button = ({
  className,
  elevated = false,
  classes,
  pulsate = false,
  primaryAction = false,
  size = "large",
  ...props
}: ButtonProps) => {
  const localClasses = useStyles();
  return (
    <MuiButton
      className={clsx(className, {
        [localClasses.pulsate]: pulsate,
        [localClasses.primaryAction]: primaryAction,
      })}
      classes={{ ...useOverrideStyles(), ...classes }}
      disableElevation={!elevated}
      disableFocusRipple
      disableRipple
      variant="contained"
      size={size}
      {...props}
    />
  );
};
