import { Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";

import {
  ProjectQuery_project,
  ProjectQuery_project_referenceFiles,
} from "../../__generated__/ProjectQuery";
import { FileAltIcon } from "../../icons/FileAltIcon";
import { Card } from "./Card";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: theme.spacing(2, 1),
  },
  referenceFile: {
    display: "flex",
    margin: theme.spacing(1),
    flexDirection: "column",
    alignItems: "center",
  },
  referenceFileName: {
    marginTop: "4px",
    maxWidth: "100%",
  },
}));

export interface ReferenceFilesCardProps {
  project: ProjectQuery_project | null;
}

export const ReferenceFilesCard = ({ project }: ReferenceFilesCardProps) => {
  const classes = useStyles();

  if (project?.referenceFiles.length === 0) {
    return null;
  }
  return (
    <Card title="Reference Files" column={1} row={10} rowSpan={3}>
      <Grid className={classes.root} container>
        {project?.referenceFiles.map((referenceFile) => (
          <Grid item key={referenceFile.id} xs={6}>
            <ReferenceFileItem
              key={referenceFile.id}
              referenceFile={referenceFile}
            />
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

const ReferenceFileItem = ({
  referenceFile,
}: {
  referenceFile: ProjectQuery_project_referenceFiles;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.referenceFile}>
      <FileAltIcon fontSize="large" />
      <Typography className={classes.referenceFileName} variant="body2" noWrap>
        {referenceFile.name}
      </Typography>
    </div>
  );
};
