import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    whiteSpace: "pre-line",
    textAlign: "center",
    fontSize: "12px",
    backgroundColor: theme.palette.grey[700],
  },
  helpCursor: {
    cursor: "help",
  },
}));

export interface TooltipProps extends MuiTooltipProps {
  disabled?: boolean;
  help?: boolean;
}

export const Tooltip = ({
  classes,
  arrow = true,
  disabled = false,
  help = false,
  children,
  ...props
}: TooltipProps) => {
  const { helpCursor, ...overrideClasses } = useStyles();
  if (disabled) {
    return children;
  }

  return (
    <MuiTooltip
      className={clsx({ [helpCursor]: help })}
      classes={{ ...overrideClasses, ...classes }}
      children={children}
      {...props}
    />
  );
};
