import { Input, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  h1: theme.typography.h1,
  h2: theme.typography.h2,
  h3: theme.typography.h3,
  underline: {
    "&:before": {
      border: "none !important",
    },
  },
  inputOffset: {
    marginTop: "-1px",
  },
  header: {
    padding: "5px 0",
    "&::placeholder": {
      color: "#D4D4D4",
    },
  },
}));

export interface EditableHeaderProps {
  className: string;
  variant: "h1" | "h2" | "h3";
  value: string;
  placeholder?: string;
  onChange: React.ReactEventHandler<{}>;
  error?: boolean;
}

export const EditableHeader = ({
  className,
  variant,
  value,
  placeholder,
  onChange,
  error = false,
}: EditableHeaderProps) => {
  const classes = useStyles();
  const [editing, setEditing] = useState(value.length === 0);

  return (
    <React.Fragment>
      {editing || !value || error ? (
        <Input
          className={clsx(classes[variant], classes.inputOffset, className)}
          classes={{ input: classes.header, underline: classes.underline }}
          value={value}
          onChange={onChange}
          autoFocus
          placeholder={placeholder}
          onFocus={() => setEditing(true)}
          onBlur={() => setEditing(false)}
          fullWidth
          error={error}
        />
      ) : (
        <Typography
          className={clsx(className, classes.header)}
          variant={variant}
          onClick={() => setEditing(true)}
        >
          {value.length > 0 ? value : placeholder}
        </Typography>
      )}
    </React.Fragment>
  );
};
