import {
  Fade,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import {
  ProjectFileStatus,
  ProjectStatus,
} from "../../__generated__/globalTypes";
import {
  ProjectQuery_project,
  ProjectQuery_project_files,
} from "../../__generated__/ProjectQuery";
import { DownloadIcon } from "../../icons/DownloadIcon";
import { FileAltIcon } from "../../icons/FileAltIcon";
import { useAnalytics } from "../../lib/analytics";
import { useAuth } from "../Auth/context";
import { Button } from "../Button";
import { LinearProgress } from "../LinearProgress";
import { Link } from "../Link";
import { Card } from "./Card";

const useStyles = makeStyles((theme) => ({
  file: {
    marginTop: theme.spacing(1),
    padding: "8px 16px 8px 8px",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  FileAltIcon: {
    display: "flex",
    fontSize: "35px",
    justifyContent: "center",
    margin: theme.spacing(1),
  },
  fileDetails: {
    flexGrow: 1,
    padding: "0px 12px",
  },
  fileProgressDetails: {
    display: "flex",
  },
  fileProgressStatus: {
    marginTop: "4px",
    color: "#4F4F4F",
    fontWeight: 500,
  },
  fileProgressLanguages: {
    paddingLeft: "2px",
    marginTop: "4px",
    color: "#4F4F4F",
  },
  downloadOverlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "0px",
    left: "0px",
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    color: theme.palette.success.main,
  },
}));

export interface ProjectFilesCardProps {
  project: ProjectQuery_project | null;
}

export const ProjectFilesCard = ({ project }: ProjectFilesCardProps) => {
  const theme = useTheme();
  const isSmallLayout = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Card
      title="Project Files"
      column={2}
      row={1}
      rowSpan={isSmallLayout ? 11 : 12}
      renderAction={
        project
          ? (props) => <DownloadAllButton project={project} {...props} />
          : undefined
      }
    >
      {project &&
        project.files.map((file) => <FileSummary key={file.id} file={file} />)}
    </Card>
  );
};

const DownloadAllButton = ({
  project,
  ...props
}: {
  project: ProjectQuery_project;
}) => {
  const auth = useAuth();
  const analytics = useAnalytics();

  const [downloadAllUrl, setDownloadAllUrl] = useState<string | undefined>();

  useEffect(() => {
    if (project?.status === ProjectStatus.COMPLETED) {
      auth
        .addTokensToUrl(`/api/files/project/${project.id}/all-files`)
        .then((downloadUrl) => setDownloadAllUrl(downloadUrl));
    }
  }, [project, auth, setDownloadAllUrl]);

  return project.status === ProjectStatus.COMPLETED &&
    project.files.length > 0 ? (
    <Button
      startIcon={<DownloadIcon />}
      size="small"
      variant="outlined"
      href={downloadAllUrl}
      disabled={!downloadAllUrl}
      onClick={() => analytics.event("Project Details", "Click Download All")}
      {...props}
    >
      Download All
    </Button>
  ) : null;
};

const FileSummary = ({ file }: { file: ProjectQuery_project_files }) => {
  const classes = useStyles();
  const [hover, setHover] = useState<boolean>(false);

  return (
    <div
      className={classes.file}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <FileAltIcon className={classes.FileAltIcon} />
      <div className={classes.fileDetails}>
        <Typography variant="body1" gutterBottom>
          {file.name}
        </Typography>
        <ProgressSummary file={file} />
      </div>
      <DownloadOverlay file={file} hover={hover} />
    </div>
  );
};

const ProgressSummary = ({ file }: { file: ProjectQuery_project_files }) => {
  let progress =
    file.translations
      .map((translation) => translation.progress.percent)
      .reduce((acc, progress) => acc + progress, 0) / file.translations.length;
  if (file.status !== ProjectFileStatus.COMPLETED) {
    progress = Math.min(90, progress);
  }

  return (
    <React.Fragment>
      <LinearProgress value={progress} />
      <ProgressDetails file={file} />
    </React.Fragment>
  );
};

const ProgressDetails = ({ file }: { file: ProjectQuery_project_files }) => {
  const classes = useStyles();
  return (
    <div className={classes.fileProgressDetails}>
      <Typography className={classes.fileProgressStatus} variant="subtitle2">
        {getStatusLabel(file.status)}
      </Typography>
      <Typography className={classes.fileProgressLanguages} variant="subtitle2">
        •{" "}
        {file.translations
          .map((translation) => translation.language.name)
          .filter((language): language is string => language != null)
          .join(", ")}
      </Typography>
    </div>
  );
};

const getStatusLabel = (status: ProjectFileStatus) => {
  switch (status) {
    case ProjectFileStatus.CREATED:
    case ProjectFileStatus.IN_PROGRESS:
      return "In Progress";
    case ProjectFileStatus.COMPLETED:
      return "Completed";
  }
};

const DownloadOverlay = ({
  hover,
  file,
}: {
  hover: boolean;
  file: ProjectQuery_project_files;
}) => {
  const classes = useStyles();
  const auth = useAuth();
  const analytics = useAnalytics();

  const [downloadUrl, setDownloadUrl] = useState<string | undefined>();

  useEffect(() => {
    if (file.downloadUrl) {
      auth
        .addTokensToUrl(file.downloadUrl)
        .then((downloadUrl) => setDownloadUrl(downloadUrl));
    }
  }, [file.downloadUrl, auth, setDownloadUrl]);

  return (
    <Fade in={hover && downloadUrl != null}>
      <div className={classes.downloadOverlay}>
        <Typography variant="body1">
          <Link
            href={downloadUrl}
            color="inherit"
            onClick={() =>
              analytics.event("Project Details", "Click Download File")
            }
          >
            Download
          </Link>
        </Typography>
      </div>
    </Fade>
  );
};
