import _ from "lodash";
import queryString from "query-string";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";

export interface NextParamOptions {
  toCurrentPage?: boolean;
}
export interface AddNextParamFunction {
  (url: string): string;
}

export const useNextParam = (
  { toCurrentPage }: NextParamOptions | undefined = { toCurrentPage: false }
): [string, AddNextParamFunction] => {
  const location = useLocation();
  const [next, setNext] = useState(toCurrentPage ? location.pathname : "/");

  useEffect(() => {
    const { next } = queryString.parse(location.search);
    if (next && _.isString(next) && !toCurrentPage) {
      setNext(next as string);
    }
  }, [location.search, toCurrentPage, setNext]);

  const addNextParam = useCallback(
    (url: string) => {
      const parsed = queryString.parseUrl(url);
      return queryString.stringifyUrl({
        url: parsed.url,
        query: {
          ...parsed.query,
          next: next !== "/" ? next : undefined,
        },
      });
    },
    [next]
  );

  return [next, addNextParam];
};
