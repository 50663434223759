import { CssBaseline as MuiCssBaseline, useTheme } from "@material-ui/core";
import React from "react";

import { DatePickerStyles } from "../DatePicker";

export const CssBaseline = () => {
  return (
    <React.Fragment>
      <MuiCssBaseline />
      <SvgDefs />
      <DatePickerStyles />
    </React.Fragment>
  );
};

const SvgDefs = () => {
  const theme = useTheme();

  return (
    <svg
      aria-hidden="true"
      focusable="false"
      style={{ width: 0, height: 0, position: "absolute" }}
    >
      <linearGradient id="gradient-primary" x2="0" y2="1">
        <stop offset="0%" stopColor={theme.palette.primary.main}></stop>
        <stop offset="100%" stopColor={theme.palette.primary.light}></stop>
      </linearGradient>
    </svg>
  );
};
