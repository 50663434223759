interface AuthTokens {
  accessToken: string;
  expiresAt: number;
  refreshToken: string;
}

export class AuthStorage {
  private storage: Storage;

  constructor() {
    this.storage = localStorage;
  }

  saveTokens(tokens: Partial<AuthTokens>) {
    if (tokens.accessToken) {
      this.storage.setItem("access_token", tokens.accessToken);
    }
    if (tokens.expiresAt) {
      this.storage.setItem("expires_at", tokens.expiresAt.toString());
    }
    if (tokens.refreshToken) {
      this.storage.setItem("refresh_token", tokens.refreshToken);
    }
  }

  loadTokens(): Partial<AuthTokens> {
    const expiresAt = this.storage.getItem("expires_at");
    return {
      accessToken: this.storage.getItem("access_token") || undefined,
      expiresAt: expiresAt ? parseInt(expiresAt) : undefined,
      refreshToken: this.storage.getItem("refresh_token") || undefined,
    };
  }

  clearTokens() {
    this.storage.removeItem("access_token");
    this.storage.removeItem("expires_at");
    this.storage.removeItem("refresh_token");
  }
}
