import { Grid, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import prettyBytes from "pretty-bytes";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { PaperClipIcon } from "../../icons/PaperClipIcon";
import { TrashIcon } from "../../icons/TrashIcon";
import { IconButton } from "../IconButton";
import { Link } from "../Link";

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: "16px",
    outline: "none",
    transition: theme.transitions.create(["border-color"], {
      duration: theme.transitions.duration.shorter,
    }),
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
  },
  headerText: {
    fontSize: "14px",
    fontFamily: "Playfair Display,serif",
  },
  icon: {
    marginRight: "4px",
    fontSize: "20px",
  },
  error: {
    borderColor: theme.palette.error.main,
  },
  fileList: {
    borderTop: "1px solid",
    borderColor: theme.palette.grey[500],
    padding: "7px 18px",
    overflow: "auto",
    maxHeight: "150px",

    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
  },
  file: {
    padding: "5px",
  },
  fileName: {
    fontWeight: 600,
  },
  fileControls: {
    display: "flex",
    justifyContent: "space-around",
  },
}));

export interface FileUploadProps {
  allowedExtensions?: string[];
  className?: string;
  files: File[];
  onChange: (files: File[]) => void;
  error?: boolean;
}

export const FileUpload = ({
  className,
  allowedExtensions,
  files,
  onChange,
  error = false,
}: FileUploadProps) => {
  const classes = useStyles();

  const handleDrop = useCallback(
    (acceptedFiles) => {
      onChange([...files, ...acceptedFiles]);
    },
    [onChange, files]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: allowedExtensions,
    onDrop: handleDrop,
  });

  const handleDelete = useCallback(
    (file) => {
      onChange(files.filter((f) => f !== file));
    },
    [onChange, files]
  );

  return (
    <div
      {...getRootProps({
        className: clsx(classes.root, { [classes.error]: error }, className),
      })}
    >
      <input {...getInputProps()} />
      <div className={classes.header}>
        <PaperClipIcon className={classes.icon} color="primary" />
        <Typography className={classes.headerText} variant="body1">
          <Link underline="hover">Add File</Link> or drop here
        </Typography>
      </div>
      {files.length > 0 && (
        <div className={classes.fileList} onClick={(e) => e.stopPropagation()}>
          {files.map((file, i) => (
            <FileInfo key={i} file={file} onDelete={() => handleDelete(file)} />
          ))}
        </div>
      )}
    </div>
  );
};

const FileInfo = ({ file, onDelete }: { file: File; onDelete: () => void }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.file} container>
      <Grid item xs={9}>
        <Typography className={classes.fileName} variant="body1" noWrap>
          {file.name}
        </Typography>
      </Grid>
      <Grid className={classes.fileControls} item xs={3}>
        <Typography variant="body1">{prettyBytes(file.size)}</Typography>
        <IconButton size="small" onClick={() => onDelete()}>
          <TrashIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
