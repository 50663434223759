import axios, { AxiosResponse } from "axios";
import * as HttpStatus from "http-status-codes";

export interface AuthUser {
  id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
}

export interface AuthTokens {
  accessToken: string;
  expiresAt: number;
  refreshToken: string;
}

export enum AuthChallengeName {
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
}

export interface AuthChallenge {
  name: AuthChallengeName;
  session: string;
  parameters: { [key: string]: string };
}

export enum AuthError {
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
}

export interface AuthResult {
  tokens?: AuthTokens;
  challenge?: AuthChallenge;
  error?: AuthError;
}

export class AuthClient {
  signIn(email: string, password: string): Promise<AuthResult> {
    return axios
      .post<AuthResult>("/api/auth/sign-in", { email, password })
      .then(this.handleAuthResponse);
  }

  completeChallenge(
    name: AuthChallengeName,
    session: string,
    parameters: { [key: string]: string }
  ): Promise<AuthResult> {
    return axios
      .post<AuthResult>("/api/auth/complete-challenge", {
        name,
        session,
        parameters,
      })
      .then(this.handleAuthResponse);
  }

  updatePassword(accessToken: string, newPassword: string): Promise<void> {
    return axios
      .post<AuthResult>(
        "/api/auth/update-password",
        {
          newPassword,
        },
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(() => {});
  }

  refreshTokens(refreshToken: string): Promise<AuthResult> {
    return axios
      .post<AuthResult>("/api/auth/refresh-tokens", { refreshToken })
      .then(this.handleAuthResponse);
  }

  private handleAuthResponse(response: AxiosResponse<AuthResult>): AuthResult {
    if (response.status !== HttpStatus.OK) {
      console.error("Received an error while signing in:", response.statusText);
      throw new Error("Unexpected server error");
    }

    if (response.data == null) {
      console.error("Received an empty response while signing in");
      throw new Error("Empty server response");
    }

    return response.data;
  }
}
