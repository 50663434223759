import "moment-business-days";

import { useQuery } from "@apollo/react-hooks";
import {
  DialogContent,
  Fade,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";

import { FileExtensionsQuery } from "../../__generated__/FileExtensionsQuery";
import { ServiceType } from "../../__generated__/globalTypes";
import {
  LanguagesQuery,
  LanguagesQuery_languages,
} from "../../__generated__/LanguagesQuery";
import { popularLanguages } from "../../constants";
import { useAnalytics } from "../../lib/analytics";
import { FILE_EXTENSIONS_QUERY, LANGUAGES_QUERY } from "../../lib/graphql";
import { AutocompleteMultiple, AutocompleteSingle } from "../Autocomplete";
import { Button } from "../Button";
import { Checkbox } from "../Checkbox";
import { DatePicker } from "../DatePicker";
import { DialogContentSection } from "../DialogContentSection";
import { EditableHeader } from "../EditableHeader";
import { FileUpload } from "../FileUpload";
import { TextField } from "../TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "625px",
    padding: "30px 40px 10px",
    paddingTop: "30px !important",
  },
  label: {
    fontSize: "17px",
    marginTop: "30px",
    marginBottom: "10px",
  },
  name: {
    fontSize: "44px",
  },
  language: {
    width: "215px",
  },
  deadline: {},
  deadlineHidden: {
    display: "none",
  },
  optionLabel: {
    maxWidth: "270px",
    fontSize: "14px",
  },
  submit: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    marginBottom: "16px",
  },
  button: {
    minWidth: "200px",
  },
}));

export interface NewProjectData {
  service?: ServiceType;
  name?: string;
  description?: string;
  sourceLanguageCode?: string;
  targetLanguageCodes?: string[];
  quoteRequested?: boolean;
  deliveryAt?: number;
  files?: File[];
  referenceFiles?: File[];
}

export interface NewProjectErrors {
  name?: boolean;
  description?: boolean;
  sourceLanguageCode?: boolean;
  targetLanguageCodes?: boolean;
  files?: boolean;
  referenceFiles?: boolean;
  deliveryAt?: boolean;
  message?: string;
}

export interface CreateProjectFormProps {
  project: NewProjectData;
  onChange: (key: keyof NewProjectData, project: NewProjectData) => void;
  loading: boolean;
  errors: NewProjectErrors;
  onError: (errors: NewProjectErrors) => void;
  onSubmit: () => void;
}

export const CreateProjectForm = ({
  project,
  onChange,
  loading,
  errors,
  onError,
  onSubmit,
}: CreateProjectFormProps) => {
  const classes = useStyles();

  const languagesQuery = useQuery<LanguagesQuery>(LANGUAGES_QUERY);
  const fileExtensionsQuery = useQuery<FileExtensionsQuery>(
    FILE_EXTENSIONS_QUERY
  );

  const [languages, setLanguages] = useState<LanguagesQuery_languages[]>([]);
  const [fileExtensions, setFileExtensions] = useState<string[]>([]);

  useEffect(() => {
    setLanguages(languagesQuery.data?.languages || []);
  }, [setLanguages, languagesQuery]);

  useEffect(() => {
    setFileExtensions(fileExtensionsQuery.data?.fileExtensions || []);
  }, [setFileExtensions, fileExtensionsQuery]);

  return (
    <DialogContent className={classes.root}>
      <NameInput project={project} onChange={onChange} errors={errors} />
      <DialogContentSection>
        <DescriptionInput
          project={project}
          onChange={onChange}
          errors={errors}
        />
        <LanguagesInput
          project={project}
          languages={languages}
          onChange={onChange}
          errors={errors}
        />
      </DialogContentSection>
      <DialogContentSection>
        <ProjectFilesInput
          project={project}
          onChange={onChange}
          errors={errors}
          allowedExtensions={fileExtensions}
        />
        <ReferenceFilesInput
          project={project}
          onChange={onChange}
          errors={errors}
        />
      </DialogContentSection>
      <DialogContentSection>
        <DeadlineInput
          project={project}
          onChange={onChange}
          errors={errors}
          onError={onError}
        />
        <OptionsInput project={project} onChange={onChange} errors={errors} />
      </DialogContentSection>
      <ErrorMessage errors={errors} />
      <SubmitButton
        onClick={() => onSubmit()}
        errors={errors}
        loading={loading}
      />
    </DialogContent>
  );
};

interface FormInputProps {
  project: NewProjectData;
  onChange: (key: keyof NewProjectData, project: NewProjectData) => void;
  errors: NewProjectErrors;
}

const NameInput = ({ project, onChange, errors }: FormInputProps) => {
  const classes = useStyles();

  const handleChange = useCallback(
    (event) => {
      onChange("name", { ...project, name: event.target.value });
    },
    [project, onChange]
  );

  return (
    <EditableHeader
      variant="h3"
      className={classes.name}
      value={project.name || ""}
      placeholder="Your Project Name"
      onChange={(event) => handleChange(event)}
      error={errors.name}
    />
  );
};

const DescriptionInput = ({ project, onChange, errors }: FormInputProps) => {
  return (
    <Input label="Details">
      <TextField
        variant="outlined"
        value={project.description}
        placeholder="Tell us about your project."
        onChange={(event) =>
          onChange("description", {
            ...project,
            description: event.target.value,
          })
        }
        multiline
        rows={3}
        error={errors.description}
      />
    </Input>
  );
};

const LanguagesInput = (
  props: FormInputProps & { languages: LanguagesQuery_languages[] }
) => {
  return (
    <Grid container spacing={5}>
      <Grid item xs>
        <SourceLanguageInput {...props} />
      </Grid>
      <Fade in={!_.isEmpty(props.project.sourceLanguageCode)}>
        <Grid item xs>
          <TargetLanguagesInput {...props} />
        </Grid>
      </Fade>
    </Grid>
  );
};

const SourceLanguageInput = ({
  project,
  languages,
  onChange,
  errors,
}: FormInputProps & { languages: LanguagesQuery_languages[] }) => {
  const classes = useStyles();

  return (
    <Input label="Source Language">
      <AutocompleteSingle
        className={classes.language}
        value={fromLanguageCode(project.sourceLanguageCode, languages) || null}
        onChange={(event, value) =>
          onChange("sourceLanguageCode", {
            ...project,
            sourceLanguageCode: value?.code || "",
          })
        }
        groupBy={groupPopularLanguages("source")}
        options={languages.sort(sortPopularLanguagesFirst("source"))}
        getOptionLabel={(option) => option.name || option.code}
        error={errors.sourceLanguageCode}
      />
    </Input>
  );
};

const TargetLanguagesInput = ({
  project,
  languages,
  onChange,
  errors,
}: FormInputProps & { languages: LanguagesQuery_languages[] }) => {
  const classes = useStyles();

  return (
    <Input label="Target Languages">
      <AutocompleteMultiple
        className={classes.language}
        value={fromLanguageCodes(project.targetLanguageCodes || [], languages)}
        onChange={(event, value) =>
          onChange("targetLanguageCodes", {
            ...project,
            targetLanguageCodes: value?.map((l) => l.code) || [],
          })
        }
        groupBy={groupPopularLanguages("target")}
        options={languages
          .filter((language) => language.code !== project.sourceLanguageCode)
          .sort(sortPopularLanguagesFirst("target"))}
        getOptionLabel={(option) => option.name || option.code}
        error={errors.targetLanguageCodes}
      />
    </Input>
  );
};

const fromLanguageCode = (
  code: string | undefined,
  languages: LanguagesQuery_languages[]
): LanguagesQuery_languages | undefined => {
  return languages.filter((language) => language.code === code)[0];
};

const fromLanguageCodes = (
  codes: string[],
  languages: LanguagesQuery_languages[]
): LanguagesQuery_languages[] => {
  const optionsByCode = _.keyBy(languages, (language) => language.code);

  return codes.map(
    (code) => optionsByCode[code] || { label: "Unknown", code: "unknown" }
  );
};

const groupPopularLanguages = (type: "source" | "target") => (
  language: LanguagesQuery_languages
): string => {
  if (_.includes(popularLanguages[type], language.code)) {
    return "a";
  }
  return "b";
};

const sortPopularLanguagesFirst = (type: "source" | "target") => (
  a: LanguagesQuery_languages,
  b: LanguagesQuery_languages
): number => {
  const aPopular = _.includes(popularLanguages[type], a.code);
  const bPopular = _.includes(popularLanguages[type], b.code);
  if (aPopular && !bPopular) {
    return -1;
  } else if (!aPopular && bPopular) {
    return 1;
  } else {
    return (a.name || a.code).localeCompare(b.name || b.code);
  }
};

const ProjectFilesInput = ({
  project,
  onChange,
  errors,
  allowedExtensions,
}: FormInputProps & { allowedExtensions: string[] | undefined }) => {
  return (
    <Input label="Project Files">
      <FileUpload
        files={project.files || []}
        onChange={(files) => onChange("files", { ...project, files: files })}
        error={errors.files}
        allowedExtensions={allowedExtensions}
      />
    </Input>
  );
};

const ReferenceFilesInput = ({ project, onChange, errors }: FormInputProps) => {
  return (
    <Input label="Reference Files">
      <FileUpload
        files={project.referenceFiles || []}
        onChange={(files) =>
          onChange("referenceFiles", { ...project, referenceFiles: files })
        }
        error={errors.referenceFiles}
      />
    </Input>
  );
};

const DeadlineInput = ({
  project,
  onChange,
  errors,
  onError,
}: FormInputProps & { onError: (errors: NewProjectErrors) => void }) => {
  const classes = useStyles();
  const [hasDeadline, setHasDeadline] = useState<boolean>(false);
  const [pricing, setPricing] = useState<string | null>();

  useEffect(() => {
    if (!project.deliveryAt) {
      setPricing(null);
      return;
    }
    const now = moment(Date.now());
    const deliveryAt = moment(project.deliveryAt);

    if (now.businessAdd(1, "day").isAfter(deliveryAt)) {
      setPricing("Express pricing");
    } else if (now.businessAdd(3, "days").isAfter(deliveryAt)) {
      setPricing("Rush pricing");
    } else {
      setPricing("Standard pricing");
    }
  }, [project.deliveryAt, setPricing]);

  return (
    <Input label="Additional Requests">
      <Grid container spacing={5}>
        <Grid item xs>
          <FormGroup>
            <FormControlLabel
              classes={{ label: classes.optionLabel }}
              control={
                <Checkbox
                  value={project.quoteRequested}
                  onChange={(event, checked) => {
                    onChange("deliveryAt", {
                      ...project,
                      deliveryAt: checked
                        ? moment(Date.now())
                            .businessAdd(4, "days")
                            .hour(17)
                            .minute(0)
                            .unix() * 1000
                        : undefined,
                    });
                    setHasDeadline(checked);
                  }}
                />
              }
              label="I need this by a specific date"
            />
          </FormGroup>
        </Grid>
        <Fade in={hasDeadline}>
          <Grid item xs>
            <DatePicker
              className={clsx(classes.deadline, {
                [classes.deadlineHidden]: !hasDeadline,
              })}
              disableWeekends
              disablePast
              value={project.deliveryAt || null}
              onChange={(value) => {
                onChange("deliveryAt", {
                  ...project,
                  deliveryAt: value || (hasDeadline ? NaN : undefined),
                });
                if (
                  moment(Date.now())
                    .businessAdd(1, "hours")
                    .isAfter(moment(value))
                ) {
                  onError({ ...errors, deliveryAt: true });
                }
              }}
              error={errors.deliveryAt}
            />
            <Typography variant="body2" align="right">
              {pricing || "\u00A0"}
            </Typography>
          </Grid>
        </Fade>
      </Grid>
    </Input>
  );
};

const OptionsInput = ({ project, onChange }: FormInputProps) => {
  const classes = useStyles();
  return (
    <Input>
      <FormGroup>
        <FormControlLabel
          classes={{ label: classes.optionLabel }}
          control={
            <Checkbox
              value={project.quoteRequested}
              onChange={(event, checked) =>
                onChange("quoteRequested", {
                  ...project,
                  quoteRequested: checked,
                })
              }
            />
          }
          label="I need a quote for this project"
        />
      </FormGroup>
    </Input>
  );
};

const ErrorMessage = ({ errors }: { errors: NewProjectErrors }) => {
  return (
    <Fade in={errors.message != null}>
      <Typography align="center" variant="body1" color="error">
        {errors.message || "\u00A0"}
      </Typography>
    </Fade>
  );
};

const SubmitButton = ({
  onClick,
  loading,
  errors,
}: {
  onClick: React.ReactEventHandler<{}>;
  loading: boolean;
  errors: NewProjectErrors;
}) => {
  const classes = useStyles();
  const analytics = useAnalytics();
  const anyErrors =
    Object.values(errors).filter((value) => value === true).length > 0;

  return (
    <div className={classes.submit}>
      <Button
        className={classes.button}
        color="primary"
        primaryAction
        onClick={(event) => {
          analytics.event("New Project", "Click Submit");
          onClick(event);
        }}
        disabled={loading || anyErrors}
      >
        Submit
      </Button>
    </div>
  );
};

export const Input = ({
  label,
  children,
}: {
  label?: string;
  children: React.ReactNode;
}) => {
  const classes = useStyles();

  return (
    <div>
      {label ? (
        <Typography className={classes.label} variant="h3">
          {label}
        </Typography>
      ) : (
        <br />
      )}
      {children}
    </div>
  );
};
