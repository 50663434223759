import {
  InputAdornment,
  OutlinedTextFieldProps as MuiOutlinedTextFieldProps,
  StandardTextFieldProps as MuiStandardTextFieldProps,
  TextField as MuiTextField,
  makeStyles,
  useForkRef,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";

import { PencilIcon } from "../../icons/PencilIcon";
import { Button } from "../Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    fontSize: theme.typography.fontSize,
  },
  input: {
    padding: "17px 16px 15px",
  },
  inputMultiline: {
    padding: "0px",
  },
  inputAdornedEnd: {
    paddingRight: "0px",
  },
  adornedEnd: {
    paddingRight: "5px",
  },
  standard: {
    borderRadius: "12px",
    boxShadow: "0px 6px 24px rgba(0, 0, 0, 0.06)",
  },
  standardRoot: {
    borderRadius: "10px",
  },
  standardBorder: {
    transition: theme.transitions.create(["border-color", "border-width"], {
      duration: theme.transitions.duration.shortest,
    }),
    borderWidth: "0px !important",
  },
  standardError: {
    borderWidth: "2px !important",
  },
  outlined: {},
  outlinedRoot: {
    borderRadius: "10px",
    backgroundColor: theme.palette.grey[100],
    "&:hover fieldset": {
      borderColor: `${theme.palette.grey[300]} !important`,
    },
    "&.Mui-disabled fieldset": {
      borderColor: `${theme.palette.grey[300]} !important`,
    },
    "&.Mui-focused fieldset": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
    "&.Mui-error fieldset": {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },
  outlinedBorder: {
    transition: theme.transitions.create(["border-color"], {
      duration: theme.transitions.duration.shortest,
    }),
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  editButton: {
    marginLeft: "-14px",
    marginRight: "3px",
  },
  visibilityButton: {
    fontWeight: 300,
  },
}));

export interface StandardTextFieldProps extends MuiStandardTextFieldProps {
  spellCheck?: boolean;
}

export interface OutlinedTextFieldProps extends MuiOutlinedTextFieldProps {
  spellCheck?: boolean;
}

export type TextFieldProps = (
  | MuiStandardTextFieldProps
  | MuiOutlinedTextFieldProps
) & {
  spellCheck?: boolean;
  showEditButton?: boolean;
  modified?: boolean;
  disabledEditButton?: boolean;
};

export const TextField = React.forwardRef<any, TextFieldProps>(
  (
    {
      className,
      spellCheck = false,
      showEditButton = false,
      disabledEditButton = false,
      modified = false,
      type,
      onBlur,
      onFocus,
      variant = "outlined",
      inputProps,
      InputProps,
      inputRef,
      ...props
    },
    ref
  ) => {
    const localInputRef = useRef<HTMLInputElement>();
    const classes = useStyles();
    const [focused, setFocused] = useState(false);
    const passwordProps = usePasswordProps({ disabled: props.disabled });
    const editButtonProps = useEditButtonProps({
      disabledEditButton,
      modified,
      focused,
      inputEl: localInputRef.current,
    });

    return (
      <MuiTextField
        ref={ref}
        className={clsx(classes.root, classes[variant], className)}
        type={type}
        inputRef={
          inputRef ? useForkRef(localInputRef, inputRef) : localInputRef
        }
        margin="dense"
        variant="outlined"
        inputProps={{
          spellCheck,
          ...inputProps,
        }}
        InputProps={{
          ...InputProps,
          ...(variant === "standard" && {
            classes: {
              root: classes.standardRoot,
              notchedOutline: clsx(classes.standardBorder, {
                [classes.standardError]: props.error,
              }),
              input: classes.input,
              inputMultiline: classes.inputMultiline,
              inputAdornedEnd: classes.inputAdornedEnd,
              adornedEnd: classes.adornedEnd,
            },
          }),
          ...(variant === "outlined" && {
            classes: {
              root: classes.outlinedRoot,
              notchedOutline: classes.outlinedBorder,
              input: classes.input,
              inputMultiline: classes.inputMultiline,
              inputAdornedEnd: classes.inputAdornedEnd,
              adornedEnd: classes.adornedEnd,
            },
          }),
          ...(type === "password" && passwordProps),
          ...(showEditButton && editButtonProps),
        }}
        onFocus={(event) => {
          onFocus?.(event);
          setFocused(true);
        }}
        onBlur={(event) => {
          onBlur?.(event);
          setFocused(false);
        }}
        {...props}
      />
    );
  }
);

const usePasswordProps = ({ disabled }: { disabled?: boolean }) => {
  const [visible, setVisible] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (disabled) {
      setVisible(false);
    }
  }, [disabled, setVisible]);
  if (disabled) {
    return null;
  }

  return {
    type: visible ? "text" : "password",
    endAdornment: (
      <InputAdornment position="end">
        <Button
          className={classes.visibilityButton}
          aria-label="toggle password visibility"
          variant="text"
          size="small"
          onClick={() => setVisible(!visible)}
          onMouseDown={(event) => event.preventDefault()}
        >
          {visible ? "Hide" : "Show"}
        </Button>
      </InputAdornment>
    ),
  };
};

const useEditButtonProps = ({
  disabledEditButton,
  modified,
  focused,
  inputEl,
}: {
  disabledEditButton: boolean;
  modified: boolean;
  focused: boolean;
  inputEl: any | undefined;
}) => {
  const classes = useStyles();
  const [editing, setEditing] = useState<boolean>(false);

  useEffect(() => {
    if (!focused && !modified) {
      setEditing(false);
    }
  }, [focused, modified, setEditing]);

  return {
    startAdornment: (
      <InputAdornment position="end">
        <Button
          className={classes.editButton}
          aria-label="toggle editing"
          onClick={() => {
            setEditing(true);
            setImmediate(() => inputEl?.focus());
          }}
          onMouseDown={(event) => event.preventDefault()}
          size="small"
          color={editing ? "primary" : "inherit"}
          startIcon={<PencilIcon />}
          disabled={disabledEditButton}
        >
          Edit
        </Button>
      </InputAdornment>
    ),
    disabled: !editing,
  };
};
