import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { filter, map } from "rxjs/operators";

import {
  AuthChallenge,
  AuthChallengeName,
  AuthClient,
  AuthResult,
} from "./client";
import { AuthStorage } from "./storage";

const TOKEN_EXPIRATION_PADDING_MS = 60000;

export enum AuthStatus {
  NONE = "NONE",
  CHALLENGED = "CHALLENGED",
  SIGNED_IN = "SIGNED_IN",
}

export class AuthService {
  private readonly client: AuthClient;
  private readonly storage: AuthStorage;
  private readonly status: BehaviorSubject<AuthStatus>;
  private challenge: AuthChallenge | undefined;

  constructor() {
    this.client = new AuthClient();
    this.storage = new AuthStorage();
    this.status = new BehaviorSubject<AuthStatus>(
      this.hasToken() ? AuthStatus.SIGNED_IN : AuthStatus.NONE
    );
  }

  signIn(email: string, password: string): Promise<AuthResult> {
    return this.client
      .signIn(email, password)
      .then((result) => this.handleAuthResult(result));
  }

  onSignIn(callback: () => void): Subscription {
    return this.status
      .pipe(filter((status) => status === AuthStatus.SIGNED_IN))
      .subscribe(() => callback());
  }

  getStatus(): Observable<AuthStatus> {
    return this.status;
  }

  isSignedIn(): Observable<boolean> {
    return this.status.pipe(map((status) => status === AuthStatus.SIGNED_IN));
  }

  getChallenge(): AuthChallenge | undefined {
    return this.challenge;
  }

  setNewPassword(newPassword: string): Promise<AuthResult> {
    if (!this.challenge) {
      throw new Error("Not challenged");
    }
    return this.client
      .completeChallenge(
        AuthChallengeName.NEW_PASSWORD_REQUIRED,
        this.challenge?.session,
        {
          USERNAME: this.challenge?.parameters.USER_ID_FOR_SRP,
          NEW_PASSWORD: newPassword,
        }
      )
      .then((result) => this.handleAuthResult(result));
  }

  async updatePassword(newPassword: string): Promise<void> {
    const accessToken = await this.refreshIfNeededAndGetAccessToken();
    if (!accessToken) {
      throw new Error();
    }
    return this.client.updatePassword(accessToken, newPassword);
  }

  private handleAuthResult(authResult: AuthResult): AuthResult {
    if (authResult.challenge) {
      this.challenge = authResult.challenge;
      this.status.next(AuthStatus.CHALLENGED);
    } else if (authResult.tokens) {
      this.storage.saveTokens(authResult.tokens);
      this.status.next(AuthStatus.SIGNED_IN);
    }
    return authResult;
  }

  signOut(): Promise<void> {
    this.storage.clearTokens();
    this.status.next(AuthStatus.NONE);
    return Promise.resolve();
  }

  onSignOut(callback: () => void): Subscription {
    return this.status
      .pipe(filter((status) => status === AuthStatus.NONE))
      .subscribe(() => callback());
  }

  private refreshTokens(): Promise<void> {
    const { refreshToken } = this.storage.loadTokens();
    if (!refreshToken) {
      return Promise.resolve();
    }
    return this.client
      .refreshTokens(refreshToken)
      .then((result) => this.storage.saveTokens(result.tokens || {}));
  }

  async addTokensToUrl(url: string): Promise<string> {
    if (!this.hasToken()) {
      return url;
    }

    const accessToken = await this.refreshIfNeededAndGetAccessToken();
    if (!accessToken) {
      return url;
    }

    const alreadyHasQueryParams = url.split("?")[1]?.length > 0;
    if (alreadyHasQueryParams) {
      return url + `&accessToken=${accessToken}`;
    } else {
      return url + `?accessToken=${accessToken}`;
    }
  }

  async addTokensToRequest(operation: {
    setContext: (context: { headers: { [key: string]: string } }) => void;
  }): Promise<void> {
    const headers = await this.getAuthHeaders();
    if (headers) {
      operation.setContext({
        headers,
      });
    }
  }

  async getAuthHeaders(): Promise<{ [key: string]: string } | null> {
    if (!this.hasToken()) {
      return null;
    }

    const accessToken = await this.refreshIfNeededAndGetAccessToken();
    if (!accessToken) {
      return null;
    }

    return {
      authorization: `Bearer ${accessToken}`,
    };
  }

  private hasToken(): boolean {
    const { accessToken, refreshToken } = this.storage.loadTokens();
    return accessToken != null || refreshToken != null;
  }

  async refreshIfNeededAndGetAccessToken(): Promise<string | null> {
    if (this.isAccessTokenExpired()) {
      await this.refreshTokens();
    }
    return this.storage.loadTokens().accessToken || null;
  }

  private isAccessTokenExpired(): boolean {
    const { accessToken, expiresAt } = this.storage.loadTokens();
    if (accessToken == null || expiresAt == null) {
      return true;
    }
    return expiresAt - TOKEN_EXPIRATION_PADDING_MS <= Date.now();
  }

  handleAnyAuthenticationErrors(response: any) {
    if (this.hasPermissionDeniedError(response)) {
      this.signOut();
    }
  }

  private hasPermissionDeniedError(response: any) {
    return JSON.stringify(response).includes("Authentication required");
  }
}
