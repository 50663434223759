import { List, ListItem, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import { ProjectStatus, ServiceType } from "../../__generated__/globalTypes";
import {
  ProjectQuery_project,
  ProjectQuery_project_sourceLanguage,
} from "../../__generated__/ProjectQuery";
import { Date } from "../Date";
import { Card } from "./Card";

const useStyles = makeStyles((theme) => ({
  list: {
    padding: "8px 0px",
  },
  item: {
    padding: "6px 14px",
    minHeight: "40px",
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
  },
  longItem: {
    alignItems: "flex-start",
  },
  itemDivider: {
    borderImage: `linear-gradient(${theme.palette.primary.dark}26 0%, ${theme.palette.primary.light}26 100%) 1`,
    borderImageWidth: "0px 0px 1px 0px",
  },
  values: {},
  value: {
    fontWeight: 600,
    fontSize: "12px",
  },
  longValue: {
    paddingTop: "16px",
    marginLeft: "32px",
  },
  success: {
    padding: "5px 9px",
    borderRadius: "6px",
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
  },
}));

export interface DetailsCardProps {
  project: ProjectQuery_project | null;
}

export const DetailsCard = ({ project }: DetailsCardProps) => {
  const classes = useStyles();

  const rows = project?.referenceFiles.length === 0 ? 12 : 9;
  return (
    <Card title="Details" column={1} row={1} rowSpan={rows}>
      <List className={classes.list}>
        <DetailsCardListItem
          name="Project Number"
          value={(project && `CA${project.shortId}`) || ""}
        />
        <DetailsCardListItem
          name="Service"
          value={getProjectServiceText(project)}
        />
        <DetailsCardListItem
          name="Project Status"
          value={getProjectStatusText(project)}
          color={
            (project?.status === ProjectStatus.COMPLETED && "success") ||
            undefined
          }
        />
        <DetailsCardListItem
          name="Quote"
          value={
            project
              ? project.quoteRequested
                ? "Requested"
                : "Not Requested"
              : ""
          }
        />
        <DetailsCardListItem
          name="Created"
          value={(project && <Date precise ts={project.createdAt} />) || ""}
        />
        <DetailsCardListItem
          name="Source Language"
          value={formatLanguage(project?.sourceLanguage)}
        />
        <DetailsCardListItem
          name="Target Languages"
          values={project?.targetLanguages.map((language) =>
            formatLanguage(language)
          )}
        />
        {project?.deliveryAt && (
          <DetailsCardListItem
            name="Deadline"
            value={<Date precise ts={project.deliveryAt} />}
          />
        )}
        <DetailsCardListItem
          name="Details"
          value={project?.description || ""}
          long
        />
      </List>
    </Card>
  );
};

const getProjectServiceText = (project: ProjectQuery_project | null) => {
  switch (project?.service) {
    case ServiceType.TRANSLATION:
      return "Translation";
    case ServiceType.LOCALIZATION:
      return "Localization";
    case ServiceType.MEDIA:
      return "Media";
    case ServiceType.OTHER:
      return "Custom";
    default:
      return "";
  }
};

const getProjectStatusText = (project: ProjectQuery_project | null) => {
  switch (project?.status) {
    case ProjectStatus.WAITING_FOR_QUOTE:
      return "Waiting for Quote";
    case ProjectStatus.IN_PROGRESS:
      return "In Progress";
    case ProjectStatus.COMPLETED:
      return "Complete";
    case ProjectStatus.CANCELED:
      return "Canceled";
    default:
      return "";
  }
};

const formatLanguage = (
  language: ProjectQuery_project_sourceLanguage | undefined
): string => {
  if (language == null) {
    return "";
  }
  return `${language.name || language.code.toUpperCase()} [-${language.code}]`;
};

interface DetailsCardListItemProps {
  name: string;
  value?: string | number | JSX.Element;
  values?: (string | number | JSX.Element)[];
  color?: "success";
  long?: boolean;
}

const DetailsCardListItem = ({
  name,
  value,
  values,
  color,
  long = false,
}: DetailsCardListItemProps) => {
  const classes = useStyles();

  if (value && !values) {
    values = [value];
  }

  return (
    <ListItem
      className={clsx(classes.item, { [classes.longItem]: long })}
      classes={{ divider: classes.itemDivider }}
    >
      <Typography variant="body2">{name}</Typography>
      {values && (
        <div
          className={clsx(classes.values, {
            [classes.success]: color === "success",
          })}
        >
          {values.map((value, index) => (
            <Typography
              key={index}
              className={clsx(classes.value, { [classes.longValue]: long })}
              align="right"
              variant="body2"
            >
              {value}
            </Typography>
          ))}
        </div>
      )}
    </ListItem>
  );
};
