import * as Sentry from "@sentry/react";
import queryString from "query-string";
import { createContext, useContext } from "react";
import ReactGA from "react-ga";

export const AnalyticsContext = createContext<AnalyticsService>(
  {} as AnalyticsService
);
export const useAnalytics = () => useContext(AnalyticsContext);

const UUID_REGEX = /[0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}/;

export class AnalyticsService {
  constructor() {
    if (AnalyticsService.isDeployed()) {
      ReactGA.initialize("UA-155331330-1");
      Sentry.init({
        dsn:
          "https://dfdbc036a68a4b29ba10e0ae0eb50696@o419399.ingest.sentry.io/5332101",
      });
    }
  }

  setUserId(userId: string | null) {
    if (AnalyticsService.isDeployed()) {
      ReactGA.set({ userId, dimension1: userId });
      Sentry.configureScope((scope) => {
        scope.setUser(userId ? { id: userId } : null);
      });
    } else {
      console.debug(`[Analytics Service] DEBUG Set user ID to ${userId}`);
    }
  }

  pageview() {
    const pathWithoutIds = window.location.pathname.replace(UUID_REGEX, ":id");
    const source = queryString.parse(window.location.search).source;
    if (AnalyticsService.isDeployed()) {
      if (source) {
        ReactGA.set({ cm: source });
      }
      ReactGA.pageview(pathWithoutIds);
    } else {
      console.debug(
        `[Analytics Service] DEBUG Collected page view for path ${pathWithoutIds} with source ${
          source || "none"
        }`
      );
    }
  }

  event(category: string, action: string) {
    if (AnalyticsService.isDeployed()) {
      ReactGA.event({ category, action });
    } else {
      console.debug(
        `[Analytics Service] DEBUG Collected event with category ${category}, action ${action}`
      );
    }
  }

  error(error: any) {
    if (AnalyticsService.isDeployed()) {
      Sentry.captureException(error);
    } else {
      console.debug("[Analytics Service] DEBUG Collected exception", error);
    }
  }

  private static isDeployed() {
    return process.env.NODE_ENV === "production";
  }
}
