import { useMutation, useQuery } from "@apollo/react-hooks";
import { DialogContent, Fade, Typography, makeStyles } from "@material-ui/core";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";

import { UserType } from "../../../__generated__/globalTypes";
import {
  InviteClient,
  InviteClientVariables,
} from "../../../__generated__/InviteClient";
import {
  SmartcatClientsQuery,
  SmartcatClientsQuery_smartcatClients,
} from "../../../__generated__/SmartcatClientsQuery";
import {
  CLIENTS_QUERY,
  INVITE_CLIENT,
  SMARTCAT_CLIENTS_QUERY,
} from "../../../lib/graphql";
import { AutocompleteSingle } from "../../Autocomplete";
import { Button } from "../../Button";
import { Dialog, DialogProps } from "../../Dialog";
import { DialogContentSection } from "../../DialogContentSection";
import { PasswordCriteriaList } from "../../PasswordCriteriaList";
import { TextField } from "../../TextField";
import { Tooltip } from "../../Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "650px",
    padding: "30px 40px 10px",
    paddingTop: "30px !important",
  },
  label: {
    fontSize: "17px",
    marginTop: "30px",
    marginBottom: "10px",
  },
  submit: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    marginBottom: "16px",
  },
  button: {
    minWidth: "200px",
  },
}));

export interface InviteClientDialogProps extends DialogProps {}

export const InviteClientDialog = (props: InviteClientDialogProps) => {
  const classes = useStyles();

  const history = useHistory();

  const smartcatClientsQuery = useQuery<SmartcatClientsQuery>(
    SMARTCAT_CLIENTS_QUERY
  );
  const [smartcatClients, setSmartcatClients] = useState<
    SmartcatClientsQuery_smartcatClients[]
  >([]);
  useEffect(() => {
    setSmartcatClients(smartcatClientsQuery.data?.smartcatClients || []);
  }, [setSmartcatClients, smartcatClientsQuery]);

  const [data, setData] = useState<InviteClientData>({});
  const [errors, setErrors] = useState<InviteClientErrors>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [validPassword, setValidPassword] = useState<boolean>(false);

  const handleChange = useCallback(
    (key: keyof InviteClientData, data: InviteClientData) => {
      setErrors(_.omit(errors, key, "message"));
      setData(data);
    },
    [errors, setErrors, setData]
  );

  const [inviteClient] = useMutation<InviteClient, InviteClientVariables>(
    INVITE_CLIENT,
    { refetchQueries: [{ query: CLIENTS_QUERY }] }
  );
  const handleSubmit = useCallback(async () => {
    const [isValid, validationErrors] = validate({ data, validPassword });
    if (!isValid) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    try {
      await inviteClient({
        variables: {
          user: {
            email: data.email || "",
            firstName: data.firstName || "",
            lastName: data.lastName || "",
            temporaryPassword: data.temporaryPassword || "",
            userType: UserType.CLIENT,
            smartcatId: data.smartcat?.id || "",
          },
        },
      });
      history.push("/admin/clients");
      setData({});
      setErrors({});
    } catch (error) {
      setErrors(parseErrors({ error }));
    } finally {
      setLoading(false);
    }
  }, [data, setErrors, setLoading, validPassword, inviteClient, history]);

  return (
    <Dialog id="invite-client-dialog" scroll="body" {...props}>
      <DialogContent className={classes.root}>
        <Typography variant="h2" gutterBottom align="center">
          Invite Client
        </Typography>
        <DialogContentSection>
          <FirstNameInput data={data} onChange={handleChange} errors={errors} />
          <LastNameInput data={data} onChange={handleChange} errors={errors} />
          <EmailAddressInput
            data={data}
            onChange={handleChange}
            errors={errors}
          />
          <TemporaryPasswordInput
            data={data}
            onChange={handleChange}
            errors={errors}
            setValidPassword={setValidPassword}
          />
          <SmartcatClientInput
            data={data}
            onChange={handleChange}
            errors={errors}
            smartcatClients={smartcatClients}
          />
          <ErrorMessage errors={errors} />
          <SubmitButton
            onClick={handleSubmit}
            loading={loading}
            errors={errors}
          />
        </DialogContentSection>
      </DialogContent>
    </Dialog>
  );
};

interface FormInputProps {
  data: InviteClientData;
  onChange: (key: keyof InviteClientData, data: InviteClientData) => void;
  errors: InviteClientErrors;
}

const FirstNameInput = ({ data, onChange, errors }: FormInputProps) => {
  return (
    <Input label="First Name">
      <TextField
        value={data.firstName || ""}
        onChange={(event) =>
          onChange("firstName", {
            ...data,
            firstName: event.target.value,
          })
        }
        error={errors.firstName}
      />
    </Input>
  );
};

const LastNameInput = ({ data, onChange, errors }: FormInputProps) => {
  return (
    <Input label="Last Name">
      <TextField
        value={data.lastName || ""}
        onChange={(event) =>
          onChange("lastName", {
            ...data,
            lastName: event.target.value,
          })
        }
        error={errors.lastName}
      />
    </Input>
  );
};

const EmailAddressInput = ({ data, onChange, errors }: FormInputProps) => {
  return (
    <Input label="Email Address">
      <TextField
        value={data.email || ""}
        onChange={(event) =>
          onChange("email", {
            ...data,
            email: event.target.value,
          })
        }
        error={errors.email}
      />
    </Input>
  );
};

const TemporaryPasswordInput = ({
  data,
  onChange,
  errors,
  setValidPassword,
}: FormInputProps & { setValidPassword: (valid: boolean) => void }) => {
  return (
    <Input
      label="Temporary Password"
      help="An internal password used in the invite email"
    >
      <TextField
        type="password"
        value={data.temporaryPassword || ""}
        error={errors.temporaryPassword}
        onChange={(event) =>
          onChange("temporaryPassword", {
            ...data,
            temporaryPassword: event.target.value,
          })
        }
        onFocus={() => {
          if (!data.temporaryPassword?.length) {
            onChange("temporaryPassword", {
              ...data,
              temporaryPassword: generateRandomPassword(),
            });
          }
        }}
      />
      <PasswordCriteriaList
        newPassword={data.temporaryPassword || ""}
        spacing="dense"
        setValid={(valid) => setValidPassword(valid)}
      />
    </Input>
  );
};

const SmartcatClientInput = ({
  smartcatClients,
  data,
  onChange,
  errors,
}: FormInputProps & {
  smartcatClients: SmartcatClientsQuery_smartcatClients[];
}) => {
  return (
    <Input label="Smartcat Client">
      <AutocompleteSingle
        options={smartcatClients}
        groupBy={(option) => ""}
        getOptionLabel={(option) => option.name}
        onChange={(event, value) => {
          onChange("smartcat", { ...data, smartcat: value || undefined });
        }}
        error={errors.smartcat}
      />
    </Input>
  );
};

const ErrorMessage = ({ errors }: { errors: InviteClientErrors }) => {
  return (
    <Fade in={errors.message != null}>
      <Typography align="center" variant="body1" color="error">
        {errors.message || "\u00A0"}
      </Typography>
    </Fade>
  );
};

const SubmitButton = ({
  onClick,
  loading,
  errors,
}: {
  onClick: React.ReactEventHandler<{}>;
  loading: boolean;
  errors: InviteClientErrors;
}) => {
  const classes = useStyles();

  const anyErrors =
    Object.values(errors).filter((value) => value === true).length > 0;

  return (
    <div className={classes.submit}>
      <Button
        className={classes.button}
        color="primary"
        primaryAction
        onClick={(event) => onClick(event)}
        disabled={loading || anyErrors}
      >
        Invite
      </Button>
    </div>
  );
};

export const Input = ({
  label,
  children,
  help,
}: {
  label?: string;
  children: React.ReactNode;
  help?: string;
}) => {
  const classes = useStyles();

  return (
    <div>
      {label ? (
        <Tooltip
          title={help || ""}
          placement="top-start"
          enterDelay={1000}
          help={!!help}
        >
          <Typography className={classes.label} variant="h3">
            {label}
          </Typography>
        </Tooltip>
      ) : (
        <br />
      )}
      {children}
    </div>
  );
};

const validate = ({
  data,
  validPassword,
}: {
  data: InviteClientData;
  validPassword: boolean;
}): [boolean, InviteClientErrors] => {
  if (
    !data.firstName?.length ||
    !data.lastName?.length ||
    !data.email?.length ||
    !data.smartcat ||
    !data.temporaryPassword?.length
  ) {
    return [
      false,
      {
        firstName: _.isEmpty(data.firstName),
        lastName: _.isEmpty(data.lastName),
        email: _.isEmpty(data.email),
        smartcat: !data.smartcat,
        temporaryPassword: _.isEmpty(data.temporaryPassword),
        message: "Some required details are missing",
      },
    ];
  }
  if (!validPassword) {
    return [
      false,
      {
        temporaryPassword: true,
        message: "The temporary password isn't strong enough",
      },
    ];
  }
  return [true, {}];
};

const parseErrors = ({ error }: { error: any }): InviteClientErrors => {
  const invalidProperties = error.graphQLErrors?.[0]?.properties;
  if (invalidProperties) {
    const message = _.every(invalidProperties, (prop) => prop === "email")
      ? "The email address is invalid or is already used"
      : "Some details are invalid";
    return {
      firstName: _.includes(invalidProperties, "firstName"),
      lastName: _.includes(invalidProperties, "lastName"),
      email: _.includes(invalidProperties, "email"),
      temporaryPassword: _.includes(invalidProperties, "temporaryPassword"),
      smartcat: _.includes(invalidProperties, "smartcatId"),
      message,
    };
  } else {
    return { message: "" + error };
  }
};

const generateRandomPassword = () => {
  const categories = [
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ", // upper case
    "abcdefghijklmnopqrstuvwxyz", // lower case
    "0123456789", // numbers
    "$_.!*'()", // symbols
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789", // all
  ];

  let generated = [];
  const totalLength = 12;
  for (let i = 0; i < totalLength; i++) {
    const category = categories[Math.min(i, 4)];
    generated.push(_.sample(category));
  }
  return _.shuffle(generated).join("");
};

export interface InviteClientData {
  email?: string;
  firstName?: string;
  lastName?: string;
  temporaryPassword?: string;
  smartcat?: SmartcatClientsQuery_smartcatClients;
}

export interface InviteClientErrors {
  email?: boolean;
  firstName?: boolean;
  lastName?: boolean;
  temporaryPassword?: boolean;
  smartcat?: boolean;
  message?: string;
}
