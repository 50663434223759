import "moment-business-days";

import { withStyles } from "@material-ui/core";
import {
  KeyboardDatePicker as MuiKeyboardDatePicker,
  KeyboardDatePickerProps as MuiKeyboardDatePickerProps,
  KeyboardDateTimePicker as MuiKeyboardDateTimePicker,
} from "@material-ui/pickers";
import React, { useCallback } from "react";

import { CalendarDayIcon } from "../../icons/CalendarDayIcon";
import { ChevronIcon } from "../../icons/ChevronIcon";
import { ClockIcon } from "../../icons/ClockIcon";
import { TextField } from "../TextField";

export const DatePickerStyles = withStyles({
  "@global": {
    ".MuiPickersToolbarButton-toolbarBtn": {
      padding: "6px",
    },
  },
})(() => null);

export interface DatePickerProps
  extends Omit<MuiKeyboardDatePickerProps, "value" | "onChange"> {
  disableTime?: boolean;
  disableWeekends?: boolean;
  value: number | null;
  onChange: (value: number | null) => void;
}

export const DatePicker = ({
  variant = "inline",
  value,
  onChange,
  disableTime = false,
  disableWeekends = false,
  ...props
}: DatePickerProps) => {
  const handleChange = useCallback(
    (value) => {
      if (!value) {
        return onChange(value);
      } else if (!value.isValid()) {
        return onChange(NaN);
      }

      onChange(value.unix() * 1000);
    },
    [onChange]
  );

  const MuiDatePicker = disableTime
    ? MuiKeyboardDatePicker
    : MuiKeyboardDateTimePicker;
  return (
    <MuiDatePicker
      variant={variant}
      value={value}
      onChange={handleChange}
      shouldDisableDate={(date) => date?.isBusinessDay() === false}
      minDateMessage={null}
      maxDateMessage={null}
      format={disableTime ? "MMM D" : "MMM D [at] h:mma"}
      TextFieldComponent={TextField as any}
      {...(disableTime === false && {
        minutesStep: 5,
      })}
      views={["date"]}
      strictCompareDates
      keyboardIcon={<CalendarDayIcon />}
      dateRangeIcon={<CalendarDayIcon />}
      timeIcon={<ClockIcon />}
      leftArrowIcon={<ChevronIcon direction="left" />}
      rightArrowIcon={<ChevronIcon direction="right" />}
      {...props}
    />
  );
};
