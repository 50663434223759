import { useQuery } from "@apollo/react-hooks";
import {
  Collapse,
  Fade,
  Hidden,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";

import { UserType } from "../../__generated__/globalTypes";
import { UserQuery, UserQuery_user } from "../../__generated__/UserQuery";
import { CogIcon } from "../../icons/CogIcon";
import { HomeIcon } from "../../icons/HomeIcon";
import { SignOutIcon } from "../../icons/SignOutIcon";
import { ToolsIcon } from "../../icons/ToolsIcon";
import { useAnalytics } from "../../lib/analytics";
import { USER_QUERY } from "../../lib/graphql";
import { useAuth } from "../Auth/context";
import { Link } from "../Link";
import Logo from "./logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 0,
    position: "absolute",
    top: "0px",
    right: "0px",
    height: "200px",
    minWidth: "150px",
    padding: "8px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
    },
  },
  user: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    paddingBottom: "8px",
  },
  name: {
    textAlign: "right",
    padding: "0px 8px",
    marginRight: "4px",
  },
  dropdown: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  link: {
    display: "grid",
    gridColumnGap: "8px",
    gridTemplateColumns: "1fr 16px",
    width: "125px",
    padding: "8px",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "none",
      width: "auto",
      fontSize: "18px",
    },
  },
}));

export const UserBadge = () => {
  const classes = useStyles();
  const { loading, data } = useQuery<UserQuery>(USER_QUERY);
  const analytics = useAnalytics();

  const [user, setUser] = useState<UserQuery_user | null>(null);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (hover) {
      analytics.event("User Badge", "Hover");
    }
  }, [analytics, hover]);

  useEffect(() => {
    setUser(data?.user || null);
  }, [data, setUser]);

  return (
    <Fade in={true}>
      <div
        className={classes.root}
        onPointerEnter={() => setHover(true)}
        onPointerOver={() => setHover(true)}
        onPointerLeave={() => setHover(false)}
      >
        <User loading={loading} user={user} />
        <Dropdown open={hover} user={user} />
      </div>
    </Fade>
  );
};

const User = ({
  loading,
  user,
}: {
  loading: boolean;
  user: UserQuery_user | null;
}) => {
  const classes = useStyles();

  const name = user && buildName(user);
  return (
    <div id="user-badge" className={classes.user}>
      <img alt="Come Alive logo" src={Logo} />
      <Hidden smDown>
        {loading && <Skeleton />}
        {user && (
          <div className={classes.name}>
            <Typography id="user-badge-title" variant="body2">
              {name || user.email}
            </Typography>
            {name && (
              <Typography id="user-badge-subtitle" variant="subtitle2" noWrap>
                {user.email}
              </Typography>
            )}
          </div>
        )}
      </Hidden>
    </div>
  );
};

const buildName = ({ firstName, lastName, email }: UserQuery_user) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (firstName) {
    return firstName;
  } else {
    return null;
  }
};

const Dropdown = ({
  open,
  user,
}: {
  open: boolean;
  user: UserQuery_user | null;
}) => {
  const auth = useAuth();
  const analytics = useAnalytics();
  const history = useHistory();
  const classes = useStyles();

  const signOut = useCallback(() => {
    history.push("/sign-in");
    auth.signOut();
  }, [history, auth]);

  return (
    <Collapse in={open}>
      <div className={clsx(classes.dropdown)}>
        <DropdownLink
          href="http://comealiveusa.com"
          target="_blank"
          icon={<HomeIcon />}
        >
          Home
        </DropdownLink>
        <DropdownLink
          onClick={() => history.push("/settings")}
          icon={<CogIcon />}
        >
          Settings
        </DropdownLink>
        {user?.type === UserType.ADMIN && (
          <DropdownLink
            onClick={() => history.push("/admin")}
            icon={<ToolsIcon />}
          >
            Admin
          </DropdownLink>
        )}
        <DropdownLink
          id="user-badge-sign-out-link"
          onClick={() => {
            analytics.event("User Badge", "Sign Out");
            signOut();
          }}
          icon={<SignOutIcon />}
        >
          Sign Out
        </DropdownLink>
      </div>
    </Collapse>
  );
};

const DropdownLink = ({
  id,
  children,
  icon,
  onClick,
  href,
  target,
}: {
  id?: string;
  children: React.ReactNode;
  icon: React.ReactNode;
  onClick?: () => void;
  href?: string;
  target?: string;
}) => {
  const classes = useStyles();
  return (
    <Link
      id={id}
      className={classes.link}
      onClick={() => onClick?.()}
      href={href}
      target={target}
      color="primary"
      colored="hover"
      underline="none"
    >
      <Hidden smDown>
        <Typography variant="body2" align="right">
          {children}
        </Typography>
      </Hidden>
      {icon}
    </Link>
  );
};
