import {
  LinearProgress as MuiLinearProgress,
  LinearProgressProps as MuiLinearProgressProps,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "6px",
    backgroundColor: theme.palette.grey[300],
    borderRadius: "3px",
  },
  bar: {
    borderRadius: "3px",
  },
  barInProgress: {
    backgroundColor: theme.palette.success.main,
  },
  barCompleted: {
    backgroundColor: theme.palette.success.main,
  },
}));

export interface LinearProgressProps
  extends Omit<MuiLinearProgressProps, "color"> {}

export const LinearProgress = ({
  className,
  variant = "determinate",
  value,
  ...props
}: LinearProgressProps) => {
  const classes = useStyles();
  return (
    <MuiLinearProgress
      classes={{
        root: classes.root,
        bar: clsx(
          classes.bar,
          classes[value === 100 ? "barCompleted" : "barInProgress"]
        ),
      }}
      variant={variant}
      value={value}
      {...props}
    />
  );
};
