import {
  OutlinedTextFieldProps as MuiOutlinedTextFieldProps,
  StandardTextFieldProps as MuiStandardTextFieldProps,
  makeStyles,
} from "@material-ui/core";
import React from "react";

import { TextField } from "../TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  textField: {
    width: "300px",
  },
}));

export interface StandardTextFieldProps extends MuiStandardTextFieldProps {
  spellCheck?: boolean;
}

export interface OutlinedTextFieldProps extends MuiOutlinedTextFieldProps {
  spellCheck?: boolean;
}

export interface SearchBarProps {
  value: string | undefined;
  onChange: (value: string) => void;
}

export const SearchBar = ({ value, onChange }: SearchBarProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextField
        className={classes.textField}
        placeholder="Search"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};
