import { Fade, Grid, Hidden, Typography, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";

import { useNextParam } from "../../lib/next";
import { AuthStatus } from "../Auth";
import { useAuth } from "../Auth/context";
import { Button } from "../Button";
import { PasswordCriteriaList } from "../PasswordCriteriaList";
import { Redirect } from "../Redirect";
import { TextField } from "../TextField";
import CloudSecureGraphic from "./cloud-secure.svg";
import Logo from "./logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px",
    minHeight: "100vh",
  },
  logo: {
    position: "absolute",
    top: "20px",
    left: "20px",
  },
  directions: {
    maxWidth: "380px",
    marginBottom: "50px",
  },
  form: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "50px",
  },
  input: {
    width: "350px",
    margin: theme.spacing(4, 0, 2),
  },
  criteriaList: {
    maxWidth: "420px",
  },
  button: {
    width: "180px",
    fontWeight: 900,
    fontSize: "24px",
    marginTop: "48px",
  },
  graphic: {
    padding: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "80%",
  },
}));

export const NewPasswordPage = () => {
  const classes = useStyles();
  const auth = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState("");
  const [isValid, setValid] = useState(false);
  const [next, addNextParam] = useNextParam();

  const [authStatus, setAuthStatus] = useState(AuthStatus.CHALLENGED);

  useEffect(() => {
    const subscription = auth
      .getStatus()
      .subscribe((status) => setAuthStatus(status));
    return () => subscription.unsubscribe();
  }, [auth, setAuthStatus]);

  const handleSubmit = useCallback(
    async (event) => {
      setLoading(true);
      event.preventDefault();
      try {
        await auth.setNewPassword(newPassword);
      } finally {
        setLoading(false);
      }
    },
    [auth, newPassword]
  );

  return (
    <Fade in={authStatus === AuthStatus.CHALLENGED}>
      <div>
        <img className={classes.logo} src={Logo} alt="Come Alive Logo" />
        <Grid className={classes.root} container justify="center">
          <Grid item className={classes.form} xs={12} sm={11} md={6} lg={5}>
            <form onSubmit={handleSubmit}>
              <Typography variant="h2" gutterBottom>
                Set Your Password
              </Typography>
              <Typography
                className={classes.directions}
                variant="subtitle1"
                gutterBottom
              >
                Enter your password below. Your new password must meet the
                minimum requirements.
              </Typography>
              <TextField
                id="new-password"
                autoComplete="new-password"
                className={classes.input}
                value={newPassword}
                type="password"
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="New Password"
              />
              <PasswordCriteriaList
                className={classes.criteriaList}
                newPassword={newPassword}
                setValid={setValid}
              />
              <Button
                className={classes.button}
                color="primary"
                elevated={true}
                type="submit"
                primaryAction
                disabled={!isValid || loading}
              >
                Submit
              </Button>
              {authStatus === AuthStatus.NONE && (
                <Redirect to={addNextParam("/sign-in")} />
              )}
              {authStatus === AuthStatus.SIGNED_IN && <Redirect to={next} />}
            </form>
          </Grid>
          <Hidden smDown>
            <Grid item className={classes.graphic} md={6} lg={6}>
              <img
                className={classes.image}
                alt="Secure your account"
                src={CloudSecureGraphic}
              />
            </Grid>
          </Hidden>
        </Grid>
      </div>
    </Fade>
  );
};
