import { ApolloProvider, useQuery } from "@apollo/react-hooks";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import React, { useEffect } from "react";

import { UserQuery } from "../../__generated__/UserQuery";
import { webTheme } from "../../constants";
import { AnalyticsContext, AnalyticsService } from "../../lib/analytics";
import { GraphQlClient, USER_QUERY } from "../../lib/graphql";
import { AuthContext, AuthService } from "../Auth";

const authService = new AuthService();
const analyticsService = new AnalyticsService();
const graphqlClient = new GraphQlClient({ authService });

export interface AppProvidersProps {
  children: React.ReactNode;
}

export const AppProviders = ({ children }: AppProvidersProps) => {
  return (
    <GraphQlProvider>
      <OtherProviders>{children}</OtherProviders>
    </GraphQlProvider>
  );
};

const GraphQlProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ApolloProvider client={graphqlClient.getApolloClient()}>
      {children}
    </ApolloProvider>
  );
};

const OtherProviders = ({ children }: { children: React.ReactNode }) => {
  const userQuery = useQuery<UserQuery>(USER_QUERY);
  useEffect(() => {
    analyticsService.setUserId(userQuery.data?.user?.id || null);
  }, [userQuery]);

  return (
    <ThemeProvider theme={webTheme}>
      <AuthContext.Provider value={authService}>
        <AnalyticsContext.Provider value={analyticsService}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            {children}
          </MuiPickersUtilsProvider>
        </AnalyticsContext.Provider>
      </AuthContext.Provider>
    </ThemeProvider>
  );
};
