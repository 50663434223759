import React from "react";
import Moment from "react-moment";

export interface DateProps {
  ts: number | null | undefined;
  precise?: boolean;
}

export const Date = ({ ts, precise = false }: DateProps) => {
  return (
    <React.Fragment>
      {ts ? (
        <Moment
          local
          unix
          format={precise ? "MMM D, YYYY [at] h:mma" : "MMM Do, YYYY"}
        >
          {ts / 1000}
        </Moment>
      ) : (
        "unknown"
      )}
    </React.Fragment>
  );
};
