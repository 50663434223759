import React from "react";

import { Icon, IconProps } from "./Icon";

export interface TrashIconProps extends IconProps {}

export const TrashIcon = (props: TrashIconProps) => {
  return (
    <Icon viewBox="0 0 24 25" destructive {...props}>
      <path d="M12 24.8279C18.5683 24.8279 24 19.3962 24 12.8279C24 6.25959 18.5683 0.827881 11.9881 0.827881C5.41976 0.827881 0 6.25959 0 12.8279C0 19.3962 5.43171 24.8279 12 24.8279ZM12 23.2845C6.20938 23.2845 1.55534 18.6185 1.55534 12.8279C1.55534 7.03726 6.20938 2.38321 11.9881 2.38321C17.7787 2.38321 22.4448 7.03726 22.4566 12.8279C22.4566 18.6185 17.7907 23.2845 12 23.2845ZM9.08078 19.8747H14.9791C15.7688 19.8747 16.2952 19.3842 16.331 18.5946L16.8096 8.64044H17.659C17.9343 8.64044 18.1496 8.4251 18.1496 8.14992C18.1496 7.87474 17.9343 7.65939 17.659 7.65939H14.7638V6.61851C14.7638 5.7571 14.1535 5.18283 13.2801 5.18283H10.7438C9.88238 5.18283 9.28416 5.7571 9.28416 6.61851V7.65939H6.34098C6.06581 7.65939 5.83849 7.87474 5.83849 8.14992C5.83849 8.4251 6.06581 8.64044 6.34098 8.64044H7.25026L7.71687 18.5946C7.76472 19.3842 8.27918 19.8747 9.08078 19.8747ZM10.2533 7.65939V6.78601C10.2533 6.41513 10.5165 6.18781 10.8993 6.18781H13.0648C13.4597 6.18781 13.7228 6.41513 13.7228 6.78601V7.65939H10.2533ZM9.81058 18.5109C9.5713 18.5109 9.41577 18.3433 9.4038 18.0921L8.99703 9.98043C8.9731 9.72919 9.16452 9.54972 9.42773 9.54972C9.67898 9.54972 9.84648 9.71722 9.85845 9.96847L10.2174 18.0682C10.2293 18.3314 10.0738 18.5109 9.81058 18.5109ZM12 18.4989C11.7488 18.4989 11.5573 18.3314 11.5573 18.0682V9.98043C11.5573 9.71722 11.7248 9.54972 12 9.54972C12.2632 9.54972 12.4427 9.71722 12.4427 9.98043V18.0682C12.4427 18.3314 12.2513 18.4989 12 18.4989ZM14.1895 18.5109C13.9262 18.5109 13.7707 18.3314 13.7827 18.0682L14.1415 9.96847C14.1535 9.71722 14.3211 9.54972 14.5722 9.54972C14.8355 9.54972 15.0269 9.72919 15.0031 9.98043L14.5962 18.0921C14.5842 18.3433 14.4288 18.5109 14.1895 18.5109Z" />
    </Icon>
  );
};
