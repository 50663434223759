import { Chip, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useLayoutEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {},
  criteria: {
    margin: "4px 3px",
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    fontWeight: 500,
    fontSize: "12px",
    borderWidth: "2px",
    padding: "4px 2px",
    transition: theme.transitions.create(
      ["color", "border-color", "border-width", "padding"],
      {
        duration: theme.transitions.duration.shortest,
      }
    ),
  },
  criteriaSpacingDense: {
    margin: "2px",
    padding: "0px",
    height: "28px",
    "& .MuiChip-label": {
      padding: "0 7px",
    },
  },
  unsatisfied: {
    color: theme.palette.grey[700],
    borderColor: theme.palette.grey[700],
    padding: "5px 3px",
    borderWidth: "1px",
  },
  unsatisfiedSpacingDense: {
    padding: "1px",
  },
}));

export interface PasswordCriteriaListProps
  extends React.HTMLAttributes<HTMLDivElement> {
  spacing?: "dense" | "normal";
  newPassword: string;
  setValid?: (valid: boolean) => void;
}

export const PasswordCriteriaList = ({
  className,
  spacing = "normal",
  newPassword,
  setValid,
  ...props
}: PasswordCriteriaListProps) => {
  const classes = useStyles();

  const [tests, setTests] = useState<{ label: string; satisfied: boolean }[]>(
    []
  );

  useLayoutEffect(() => {
    const tests = newPasswordCriteria.map(({ label, test }) => ({
      label,
      satisfied: test(newPassword),
    }));
    setTests(tests);
    setValid?.(tests.every((test) => test.satisfied));
  }, [newPassword, setValid, setTests]);

  return (
    <div className={clsx(classes.root, className)} {...props}>
      {tests.map((test) => (
        <CriteriaChip
          key={test.label}
          label={test.label}
          satisfied={test.satisfied}
          spacing={spacing}
        />
      ))}
    </div>
  );
};

const newPasswordCriteria = [
  { label: "8 Letters", test: (value: string) => value.length >= 8 },
  { label: "1 Number", test: (value: string) => /[0-9]/.test(value) },
  {
    label: "1 Special Character",
    // eslint-disable-next-line
    test: (value: string) => /[\^$*.[\]{}()?"!@#%&\/\\,><':;|_~]/.test(value),
  },
  { label: "1 Uppercase Letter", test: (value: string) => /[A-Z]/.test(value) },
  { label: "1 Lowercase Letter", test: (value: string) => /[a-z]/.test(value) },
];

const CriteriaChip = ({
  label,
  satisfied,
  spacing,
}: {
  label: string;
  satisfied: boolean;
  spacing: "dense" | "normal";
}) => {
  const classes = useStyles();
  return (
    <Chip
      className={clsx(
        classes.criteria,
        {
          [classes.criteriaSpacingDense]: spacing === "dense",
          [classes.unsatisfied]: !satisfied,
          [classes.unsatisfiedSpacingDense]: !satisfied && spacing === "dense",
        },
        {}
      )}
      variant="outlined"
      label={label}
    />
  );
};
