import { InputAdornment, makeStyles } from "@material-ui/core";
import { AutocompleteRenderInputParams } from "@material-ui/lab";
import React from "react";

import { ChevronIcon } from "../../icons/ChevronIcon";
import { IconButton } from "../IconButton";
import { TextField } from "../TextField";

export const useAutocompleteStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: "10px",
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)",
    backgroundColor: theme.palette.common.white,
  },
  listbox: {
    padding: "0px",
    maxHeight: "200px",
    zIndex: 1,
    overflow: "auto",

    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "& > li + li": {
      marginTop: "1px",
      paddingTop: "1px",
      borderTop: `2px solid ${theme.palette.grey[400]}`,
    },
  },
  groupLabel: {
    display: "none",
  },
  option: {
    padding: "8px",
    display: "inherit",
    textAlign: "center",
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:first-child": {
      paddingTop: "5px",
    },
    "&:last-child": {
      paddingBottom: "5px",
    },
    "&[aria-selected='true']": {
      display: "none",
    },
  },
  noOptions: {
    display: "none",
  },
}));

export const renderAutocompleteInput = ({
  open,
  setOpen,
  focused,
  hidden = false,
  error = false,
  InputProps,
  ...params
}: AutocompleteRenderInputParams & {
  open: boolean;
  setOpen: (open: boolean) => void;
  focused: boolean;
  hidden?: boolean;
  error?: boolean;
}) => {
  return (
    <TextField
      style={{ display: hidden ? "none" : undefined }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              edge="start"
              size="small"
              onClick={() => setOpen(!open)}
            >
              <ChevronIcon
                direction={open ? "up" : "down"}
                color={focused ? "primary" : "inherit"}
              />
            </IconButton>
          </InputAdornment>
        ),
        ref: InputProps.ref,
      }}
      autoFocus={focused}
      {...params}
      variant="outlined"
      error={error}
    />
  );
};
