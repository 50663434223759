import { gql } from "apollo-boost";

export const EVENTS_QUERY = gql`
  query EventsQuery($projectId: String!) {
    events(projectId: $projectId) {
      type
      source
      occurredAt

      ... on TRANSLATIONS_COMPLETED {
        files {
          name
        }
      }
    }
  }
`;

export const FILE_EXTENSIONS_QUERY = gql`
  query FileExtensionsQuery {
    fileExtensions
  }
`;

export const LANGUAGES_QUERY = gql`
  query LanguagesQuery {
    languages {
      code
      name
    }
  }
`;

export const PROJECTS_QUERY = gql`
  query ProjectsQuery {
    projects {
      id
      name
      description
      sourceLanguage {
        code
        name
      }
      targetLanguages {
        code
        name
      }
      status
    }
  }
`;

export const PROJECT_QUERY = gql`
  query ProjectQuery($id: String!) {
    project(id: $id) {
      id
      shortId
      service
      name
      description
      sourceLanguage {
        code
        name
      }
      targetLanguages {
        code
        name
      }
      status
      quoteRequested
      referenceFiles {
        id
        name
        downloadUrl
      }
      files {
        id
        name
        status
        downloadUrl
        translations {
          id
          language {
            name
          }
          progress {
            percent
          }
        }
      }
      createdAt
      deliveryAt
    }
  }
`;

export const SETTINGS_QUERY = gql`
  query SettingsQuery {
    settings {
      billingAddress
      notifications {
        PROJECT_CREATED
        PROJECT_COMPLETED
      }
    }
  }
`;

export const SITE_STATUS_QUERY = gql`
  query SiteStatusQuery {
    siteStatus {
      alert
    }
  }
`;

export const UPDATE_SETTINGS = gql`
  mutation UpdateSettings($settings: UpdateUserSettingsInput!) {
    updateSettings(settings: $settings)
  }
`;

export const USER_QUERY = gql`
  query UserQuery {
    user {
      id
      email
      firstName
      lastName
      avatar
      type
    }
  }
`;

export const INVITE_CLIENT = gql`
  mutation InviteClient($user: InviteUserInput!) {
    inviteClient(user: $user) {
      id
    }
  }
`;

export const CLIENTS_QUERY = gql`
  query ClientsQuery {
    clients {
      id
      email
      firstName
      lastName
      type
      smartcat {
        id
        name
      }
    }
  }
`;

export const SMARTCAT_CLIENTS_QUERY = gql`
  query SmartcatClientsQuery {
    smartcatClients {
      id
      name
    }
  }
`;
