import { List, ListItem, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import { EventsQuery_events } from "../../__generated__/EventsQuery";
import { EventSource, EventType } from "../../__generated__/globalTypes";
import { Date } from "../Date";
import { Paper } from "../Paper";
import { Card } from "./Card";

const useStyles = makeStyles((theme) => ({
  list: {
    height: "100%",
  },
  item: {
    display: "flex",
    padding: "8px",
    flexDirection: "column",
    "& div": {
      display: "flex",
    },
  },
  recieved: {
    alignItems: "flex-end",
    "& div": {
      flexDirection: "row-reverse",
    },
  },
  sent: {
    alignItems: "flex-start",
    "& div": {
      flexDirection: "row",
    },
  },
  message: {
    backgroundColor: "#FAFAFA",
    padding: "6px 10px",
  },
  delivery: {
    margin: "6px",
  },
  avatar: {
    height: "22px",
    width: "22px",
  },
  timestamp: {
    margin: "0px 6px",
  },
}));

interface ActivityCardProps {
  events: EventsQuery_events[] | null;
}

export const ActivityCard = ({ events }: ActivityCardProps) => {
  return (
    <Card title="Activity" column={3} row={1} rowSpan={11}>
      <ActivitiesList events={events || []} />
    </Card>
  );
};

const ActivitiesList = ({
  events,
  className,
  onClick,
}: {
  events: EventsQuery_events[];
  className?: string;
  onClick?: React.ReactEventHandler<{}>;
}) => {
  const classes = useStyles();

  return (
    <List className={clsx(className, classes.list)} onClick={onClick}>
      {events.map((event, i) => (
        <ActivityListItem key={i} event={event} />
      ))}
    </List>
  );
};

const ActivityListItem = ({ event }: { event: EventsQuery_events }) => {
  const classes = useStyles();
  const sourceClass =
    classes[event.source === EventSource.CLIENT ? "sent" : "recieved"];
  return (
    <ListItem className={clsx(classes.item, sourceClass)}>
      <Message event={event} />
      <Delivery event={event} />
    </ListItem>
  );
};

const Message = ({ event }: { event: EventsQuery_events }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.message} elevation={0}>
      <Typography variant="body2">{buildMessageText(event)}</Typography>
    </Paper>
  );
};

const buildMessageText = (event: EventsQuery_events): string => {
  const fromClient = event.source === EventSource.CLIENT;
  switch (event.__typename) {
    case EventType.PROJECT_CREATED:
      return fromClient ? "You created the project" : "The project was created";
    case EventType.QUOTE_REQUESTED:
      return fromClient ? "You requested a quote" : "A quote was requested";
    case EventType.TRANSLATIONS_COMPLETED:
      let text;
      if (event.files.length === 0) {
        text = "Some files were completed";
      } else if (event.files.length === 1) {
        text = "A file was completed";
      } else {
        text = "Multiple files were completed";
      }
      if (event.files.length > 0) {
        text += `: ${event.files.map((file) => file.name).join(",")}`;
      }
      return text;
    case EventType.PROJECT_COMPLETED:
      return "The project was completed";
    default:
      return `[unknown activity: ${event.type}]`;
  }
};

const Delivery = ({ event }: { event: EventsQuery_events }) => {
  const classes = useStyles();

  return (
    <div className={classes.delivery}>
      <Typography className={classes.timestamp} variant="subtitle2">
        <Date ts={event.occurredAt} precise />
      </Typography>
    </div>
  );
};
