export const popularLanguages = {
  source: ["en", "fr", "it", "ja", "la", "es"],
  target: [
    "sq",
    "ar",
    "bs",
    "bg",
    "yue",
    "zh-Hans",
    "zh-Hans-CN",
    "hr",
    "cs",
    "en",
    "fr",
    "de",
    "gu",
    "ht",
    "hi",
    "hmn",
    "it",
    "ja",
    "ko",
    "la",
    "ml",
    "pa",
    "fa",
    "pl",
    "pt",
    "ru",
    "sr",
    "sk",
    "sl",
    "es",
    "tl",
    "th",
    "tr",
    "uk",
    "ur",
    "vi",
  ],
};
