import { useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Redirect as ReactRedirect,
  RedirectProps as ReactRedirectProps,
} from "react-router";

export interface RedirectProps extends ReactRedirectProps {
  delayed?: boolean;
}

export const Redirect = ({ delayed = true, ...props }: RedirectProps) => {
  const theme = useTheme();
  const [timeToRedirect, setTimeToRedirect] = useState(!delayed);

  useEffect(() => {
    if (delayed) {
      const timeout = setTimeout(
        () => setTimeToRedirect(true),
        theme.transitions.duration.leavingScreen
      );
      return () => clearTimeout(timeout);
    }
    return;
  }, [theme, delayed, setTimeToRedirect]);

  return timeToRedirect ? <ReactRedirect {...props} /> : null;
};
