import {
  Paper as MuiPaper,
  PaperProps as MuiPaperProps,
  makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  elevation1: {
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)",
  },
}));

export interface PaperProps extends MuiPaperProps {}

export const Paper = (props: PaperProps) => {
  const classes = useStyles();
  return <MuiPaper classes={classes} {...props} />;
};
