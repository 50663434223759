import {
  Link,
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  disabled: {
    color: `${theme.palette.action.disabledBackground} !important`,
  },
}));

export interface IconButtonProps extends MuiIconButtonProps {
  href?: string;
}

export const IconButton = React.forwardRef<any, IconButtonProps>(
  ({ classes, href, disabled = false, ...props }, ref) => {
    return (
      <Link ref={ref} type="button" href={disabled ? undefined : href}>
        <MuiIconButton
          classes={{ ...classes, ...useStyles() }}
          disableFocusRipple
          disableRipple
          disabled={disabled}
          {...props}
        />
      </Link>
    );
  }
);
