import { useQuery } from "@apollo/react-hooks";
import { Hidden, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import {
  EventsQuery,
  EventsQueryVariables,
  EventsQuery_events,
} from "../../__generated__/EventsQuery";
import {
  ProjectQuery,
  ProjectQueryVariables,
  ProjectQuery_project,
} from "../../__generated__/ProjectQuery";
import { CloseWindowIcon } from "../../icons/CloseWindowIcon";
import { EVENTS_QUERY, PROJECT_QUERY } from "../../lib/graphql";
import { Dialog, DialogProps } from "../Dialog";
import { IconButton } from "../IconButton";
import { ActivityCard } from "./ActivityCard";
import { AdvisorCard } from "./AdvisorCard";
import { DetailsCard } from "./DetailsCard";
import { ProjectFilesCard } from "./ProjectFilesCard";
import { ReferenceFilesCard } from "./ReferenceFilesCard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    margin: "20px",
  },
  dialog: {
    backgroundColor: "transparent",
    width: "85vw",
  },
  scrollPaper: {
    maxHeight: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "12px",
    marginRight: "-12px",
  },
  body: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "repeat(12, 1fr)",
    gridRowGap: "15px",
    gridColumnGap: "30px",
    flexDirection: "row",
    justifyContent: "stretch",
    alignItems: "stretch",
    minHeight: "500px",
    height: "70vh",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  column: {
    padding: "0px 10px",
    flexGrow: 1,
    flexBasis: 0,
    display: "flex",
    flexDirection: "column",
  },
}));

export interface ProjectDialogProps extends Partial<DialogProps> {
  open: boolean;
  projectId: string;
}

export const ProjectDialog = ({ projectId, ...props }: ProjectDialogProps) => {
  const classes = useStyles();

  const projectQueryResult = useQuery<ProjectQuery, ProjectQueryVariables>(
    PROJECT_QUERY,
    { variables: { id: projectId }, skip: !props.open }
  );
  const [project, setProject] = useState<ProjectQuery_project | null>(null);
  useEffect(() => {
    setProject(projectQueryResult.data?.project || null);
  }, [projectQueryResult.data, setProject]);

  const eventsQueryResult = useQuery<EventsQuery, EventsQueryVariables>(
    EVENTS_QUERY,
    { variables: { projectId }, skip: !props.open }
  );
  const [events, setEvents] = useState<EventsQuery_events[] | null>(null);
  useEffect(() => {
    setEvents(eventsQueryResult.data?.events || null);
  }, [eventsQueryResult.data, setProject]);

  return (
    <Dialog
      classes={{ paperScrollPaper: classes.scrollPaper }}
      PaperProps={{ elevation: 0, classes: { root: classes.dialog } }}
      disableExitButton
      maxWidth="lg"
      {...props}
    >
      <div className={classes.root}>
        <Header project={project} onClose={props.onClose} />
        <Body project={project} events={events} />
      </div>
    </Dialog>
  );
};

const Header = ({
  project,
  onClose,
}: {
  project: ProjectQuery_project | null;
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <Typography variant="h3">{project?.name}</Typography>
      <IconButton
        onClick={(event) => onClose && onClose(event, "backdropClick")}
      >
        <CloseWindowIcon />
      </IconButton>
    </div>
  );
};

const Body = ({
  project,
  events,
}: {
  project: ProjectQuery_project | null;
  events: EventsQuery_events[] | null;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <DetailsCard project={project} />
      <ReferenceFilesCard project={project} />
      <ProjectFilesCard project={project} />
      <Hidden smDown>
        <ActivityCard events={events} />
      </Hidden>
      <AdvisorCard project={project} />
    </div>
  );
};
