import { SvgIcon, SvgIconProps, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  gradient: {
    fill: "url(#gradient-primary)",
  },
  destructive: {
    transition: theme.transitions.create(["color"], {
      duration: theme.transitions.duration.shortest,
    }),
    "&:hover": {
      color: theme.palette.error.main,
    },
  },
}));

export interface IconProps extends SvgIconProps {
  gradient?: boolean;
  destructive?: boolean;
}

export const Icon = ({
  className,
  gradient = false,
  destructive = false,
  fontSize = "inherit",
  ...props
}: IconProps) => {
  const classes = useStyles();

  return (
    <SvgIcon
      className={clsx(
        {
          [classes.gradient]: gradient,
          [classes.destructive]: destructive,
        },
        className
      )}
      fontSize={fontSize}
      {...props}
    />
  );
};
