import { DialogContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useHistory, useRouteMatch } from "react-router";

import { UsersIcon } from "../../icons/UsersIcon";
import { Button } from "../Button";
import { Dialog, DialogProps } from "../Dialog";
import { DialogContentSection } from "../DialogContentSection";
import { ClientsAdminDialog } from "./Clients";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "650px",
    minHeight: "250px",
    padding: "30px 40px 10px",
    paddingTop: "30px !important",
  },
  links: {
    textAlign: "center",
  },
}));

export interface AdminDialogProps extends DialogProps {}

export const AdminDialog = ({ open, ...props }: AdminDialogProps) => {
  const classes = useStyles();

  const adminDialogRoute = useRouteMatch({
    path: "/admin",
    strict: true,
  });
  const clientsDialogRoute = useRouteMatch("/admin/clients");

  return (
    <React.Fragment>
      <Dialog open={open && adminDialogRoute != null} scroll="body" {...props}>
        <DialogContent className={classes.root}>
          <Typography variant="h2" gutterBottom>
            Administration
          </Typography>
          <DialogContentSection className={classes.links}>
            <AdminDialogLink
              icon={<UsersIcon />}
              label="Manage Clients"
              href="/admin/clients"
            />
          </DialogContentSection>
        </DialogContent>
      </Dialog>
      <ClientsAdminDialog
        open={open && clientsDialogRoute != null}
        {...props}
      />
    </React.Fragment>
  );
};

const AdminDialogLink = ({
  icon,
  label,
  href,
}: {
  icon: React.ReactNode;
  label: string;
  href: string;
}) => {
  const history = useHistory();
  return (
    <Button startIcon={icon} onClick={() => history.push(href)} color="primary">
      {label}
    </Button>
  );
};
