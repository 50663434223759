import {
  Avatar,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";

import { ProjectQuery_project } from "../../__generated__/ProjectQuery";
import { EnvolopeIcon } from "../../icons/EnvolopeIcon";
import { IconButton } from "../IconButton";
import { Card } from "./Card";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 10px",
  },
  profile: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  avatar: {
    height: "32px",
    width: "32px",
    marginRight: "10px",
  },
  contactButton: {
    marginRight: "4px",
  },
}));

export interface AdvisorCardProps {
  project: ProjectQuery_project | null;
}

interface Advisor {
  name: string;
  avatar: string;
}

export const AdvisorCard = ({ project }: AdvisorCardProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallLayout = useMediaQuery(theme.breakpoints.down("sm"));

  const advisor: Advisor = {
    name: "Bridget Hylak",
    avatar:
      "https://www.tonguetek.com/static/media/profilePicture.59f983f7.jpg",
  };

  return (
    <Card
      className={classes.root}
      title="Your Project Advisor"
      column={isSmallLayout ? 2 : 3}
      row={12}
      rowSpan={1}
    >
      <Profile advisor={advisor} />
      <Contact advisor={advisor} />
    </Card>
  );
};

const Profile = ({ advisor }: { advisor: Advisor }) => {
  const classes = useStyles();
  return (
    <div className={classes.profile}>
      <Avatar className={classes.avatar} src={advisor.avatar} />
      <Typography variant="body2">{advisor.name}</Typography>
    </div>
  );
};

const Contact = ({ advisor }: { advisor: Advisor }) => {
  const classes = useStyles();
  return (
    <div>
      <ContactButton
        className={classes.contactButton}
        Icon={EnvolopeIcon}
        href="mailto:Bridget@ComeAliveUSA.com"
      />
    </div>
  );
};

const ContactButton = ({
  className,
  href,
  Icon,
}: {
  className?: string;
  href?: string;
  Icon: (props: {}) => JSX.Element;
}) => {
  return (
    <IconButton className={className} size="small" color="primary" href={href}>
      <Icon />
    </IconButton>
  );
};
