import { useLazyQuery } from "@apollo/react-hooks";
import {
  Box,
  DialogContent,
  Grid,
  List,
  ListItem,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import Fuse from "fuse.js";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";

import {
  ClientsQuery,
  ClientsQuery_clients,
} from "../../../__generated__/ClientsQuery";
import { EllipsisIcon } from "../../../icons/EllipsisIcon";
import { PlusIcon } from "../../../icons/PlusIcon";
import { CLIENTS_QUERY } from "../../../lib/graphql";
import { Button } from "../../Button";
import { Dialog, DialogProps } from "../../Dialog";
import { DialogContentSection } from "../../DialogContentSection";
import { IconButton } from "../../IconButton";
import { Link } from "../../Link";
import { Paper } from "../../Paper";
import { SearchBar } from "../../SearchBar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "720px",
    padding: "30px 40px 10px",
    paddingTop: "30px !important",
    textAlign: "center",
  },
  actionBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  body: {
    display: "flex",
    justifyContent: "space-between",
    height: "350px",
    overflow: "visible",
  },
  clientList: {
    maxHeight: "100%",
    padding: "8px 20px",
    margin: "-8px -20px -8 0px",
    overflowY: "auto",
  },
  clientListItem: {
    border: "1px solid transparent",
    marginBottom: "16px",
    padding: "12px",
    cursor: "pointer",
    transition: theme.transitions.create(["border-color", "color"], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  adminBadge: {
    padding: "5px 9px",
    borderRadius: "6px",
    marginLeft: "8px",
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
  },
  selectedClientListItem: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  clientDetail: {
    padding: "12px",
    textAlign: "left",
    marginLeft: "32px",
  },
  detailList: {
    padding: "8px 0px",
  },
  detailItem: {
    padding: "3px 14px",
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
  },
  detailItemValue: {
    fontWeight: 600,
    fontSize: "12px",
  },
}));

export interface ManageClientsDialogProps extends DialogProps {}

export const ManageClientsDialog = (props: ManageClientsDialogProps) => {
  const classes = useStyles();

  const [fetchClients, { data }] = useLazyQuery<ClientsQuery>(CLIENTS_QUERY);

  const history = useHistory();
  const [selected, setSelected] = useState<ClientsQuery_clients | null>(null);

  const [query, setQuery] = useState<string>("");
  const [matches, setMatches] = useState<ClientsQuery_clients[]>([]);
  const fuse = useMemo(() => {
    if (data) {
      return new Fuse<ClientsQuery_clients>([...data?.clients], {
        threshold: 0.4,
        distance: 10,
        keys: ["firstName", "lastName", "email", "smartcat.name"],
        includeMatches: true,
      });
    }
    return null;
  }, [data]);

  useEffect(() => {
    if (props.open) {
      fetchClients();
    }
  }, [props.open, fetchClients]);

  useEffect(() => {
    if (data && fuse) {
      if (query) {
        setMatches(fuse.search(query).map((item) => item.item));
      } else {
        setMatches(data.clients.sort((a, b) => a.email.localeCompare(b.email)));
      }
    }
  }, [data, fuse, setMatches, query]);

  return (
    <Dialog id="manage-clients-dialog" scroll="body" {...props}>
      <DialogContent className={classes.root}>
        <Typography variant="h2" gutterBottom>
          Manage Clients
        </Typography>
        <DialogContentSection className={classes.actionBar}>
          <SearchBar value={query} onChange={(value) => setQuery(value)} />
          <Button
            startIcon={<PlusIcon />}
            color="primary"
            primaryAction
            size="medium"
            onClick={() => history.push("/admin/clients/invite")}
          >
            Invite Client
          </Button>
        </DialogContentSection>
        <DialogContentSection className={classes.body}>
          <Grid container>
            <Grid item className={classes.clientList} xs={5}>
              {matches.map((client) => (
                <ClientListItem
                  key={client.id}
                  client={client}
                  selected={selected?.id === client.id}
                  onClick={() => setSelected(client)}
                />
              ))}
            </Grid>
            <Grid item xs={7}>
              <ClientDetail client={selected} />
            </Grid>
          </Grid>
        </DialogContentSection>
      </DialogContent>
    </Dialog>
  );
};

const ClientListItem = ({
  client,
  selected,
  onClick,
}: {
  client: ClientsQuery_clients;
  selected: boolean;
  onClick: () => void;
}) => {
  const classes = useStyles();

  return (
    <Paper
      className={clsx(classes.clientListItem, {
        [classes.selectedClientListItem]: selected,
      })}
      onClick={onClick}
    >
      <React.Fragment>
        <Typography variant="body1">
          {client.firstName} {client.lastName}
        </Typography>
        <Typography variant="body2" noWrap>
          {client.email}
        </Typography>
      </React.Fragment>
    </Paper>
  );
};

const ClientDetail = ({ client }: { client: ClientsQuery_clients | null }) => {
  const classes = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = useState<any | null>(null);
  return (
    <React.Fragment>
      {client == null ? (
        <Box className={classes.clientDetail}>
          <Typography variant="subtitle1" align="center">
            Select a client from the left to see more details.
          </Typography>
        </Box>
      ) : (
        <Paper className={classes.clientDetail}>
          <React.Fragment>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1" style={{ fontSize: "24px" }}>
                  {client.firstName} {client.lastName}
                </Typography>
                {client.type === "ADMIN" && (
                  <Typography
                    variant="subtitle2"
                    className={classes.adminBadge}
                  >
                    Admin
                  </Typography>
                )}
              </Box>
              <IconButton
                size="small"
                onClick={(event) => setMenuAnchorEl(event.target)}
                disabled
              >
                <EllipsisIcon />
              </IconButton>
            </Box>
            <Menu
              anchorEl={menuAnchorEl}
              keepMounted
              open={menuAnchorEl != null}
              PaperProps={{
                style: {
                  boxShadow: "0px 6px 24px rgba(0, 0, 0, 0.08)",
                },
              }}
              onClose={() => setMenuAnchorEl(null)}
            >
              <MenuItem onClick={() => setMenuAnchorEl(null)}>Edit</MenuItem>
              <MenuItem onClick={() => setMenuAnchorEl(null)}>
                Reset Password
              </MenuItem>
              <MenuItem onClick={() => setMenuAnchorEl(null)}>
                Deactivate
              </MenuItem>
            </Menu>
            <List>
              <DetailsListItem name="First Name" value={client.firstName} />
              <DetailsListItem name="Last Name" value={client.lastName} />
              <DetailsListItem name="Email" value={client.email} />
              <DetailsListItem
                name="Smartcat Client"
                value={
                  client.smartcat ? (
                    <Link
                      href={`https://us.smartcat.com/clients/edit/${client.smartcat.id}`}
                      external
                    >
                      {client.smartcat.name}
                    </Link>
                  ) : (
                    "Not set"
                  )
                }
              />
            </List>
          </React.Fragment>
        </Paper>
      )}
    </React.Fragment>
  );
};

const DetailsListItem = ({
  name,
  value,
}: {
  name: string;
  value: string | number | JSX.Element | null;
}) => {
  const classes = useStyles();

  return (
    <ListItem className={classes.detailItem}>
      <Typography variant="body2">{name}</Typography>
      <Typography
        className={classes.detailItemValue}
        align="right"
        variant="body2"
      >
        {value || ""}
      </Typography>
    </ListItem>
  );
};
