import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  DialogContent,
  Fade,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import { EventType } from "../../__generated__/globalTypes";
import {
  SettingsQuery,
  SettingsQuery_settings,
  SettingsQuery_settings_notifications,
} from "../../__generated__/SettingsQuery";
import {
  UpdateSettings,
  UpdateSettingsVariables,
} from "../../__generated__/UpdateSettings";
import { UserQuery } from "../../__generated__/UserQuery";
import { useAnalytics } from "../../lib/analytics";
import { SETTINGS_QUERY, UPDATE_SETTINGS, USER_QUERY } from "../../lib/graphql";
import { useAuth } from "../Auth/context";
import { Button } from "../Button";
import { Checkbox } from "../Checkbox";
import { Dialog, DialogProps } from "../Dialog";
import { DialogContentSection } from "../DialogContentSection";
import { PasswordCriteriaList } from "../PasswordCriteriaList";
import { TextField } from "../TextField";
import { Tooltip } from "../Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "650px",
    padding: "30px 40px 10px",
    paddingTop: "30px !important",
  },
  label: {
    fontSize: "17px",
    marginTop: "30px",
    marginBottom: "10px",
  },
  criteriaListHidden: {
    display: "none",
  },
  submit: {
    marginTop: "20px",
    marginBottom: "16px",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    minWidth: "200px",
  },
}));

export interface SettingsDialogProps extends DialogProps {}

export const SettingsDialog = ({ onClose, ...props }: SettingsDialogProps) => {
  const classes = useStyles();
  const auth = useAuth();
  const settingsQuery = useQuery<SettingsQuery>(SETTINGS_QUERY);
  const [updateSettings] = useMutation<UpdateSettings, UpdateSettingsVariables>(
    UPDATE_SETTINGS,
    { refetchQueries: [{ query: SETTINGS_QUERY }] }
  );

  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState<SettingsQuery_settings>();
  const [modified, setModified] = useState<{
    password?: string;
    notifications?: Omit<SettingsQuery_settings_notifications, "__typename">;
  }>({});
  const [passwordValid, setPasswordValid] = useState<boolean>(false);

  useEffect(() => {
    setSettings(settingsQuery.data?.settings);
  }, [setSettings, settingsQuery]);

  const handleClose = useCallback(
    (event, reason) => {
      onClose?.(event, reason);
      setModified({});
    },
    [setModified, onClose]
  );

  const updatePasswordIfNeeded = useCallback(async () => {
    if (modified.password) {
      auth.updatePassword(modified.password);
    }
  }, [modified.password, auth]);

  const updateSettingsIfNeeded = useCallback(async () => {
    if (modified.notifications) {
      await updateSettings({
        variables: {
          settings: {
            notifications: modified.notifications,
          },
        },
      });
    }
  }, [modified.notifications, updateSettings]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    await Promise.all([updatePasswordIfNeeded(), updateSettingsIfNeeded()]);
    handleClose({}, "backdropClick");
    setLoading(false);
  }, [setLoading, updatePasswordIfNeeded, updateSettingsIfNeeded, handleClose]);

  return (
    <Dialog
      id="settings-dialog"
      scroll="body"
      confirmBeforeClose={_.values(modified).length > 0}
      onClose={handleClose}
      {...props}
    >
      <DialogContent className={classes.root}>
        <Typography variant="h2" gutterBottom>
          Settings
        </Typography>
        <DialogContentSection>
          <EmailAddressInput />
          <PasswordInput
            modifiedPassword={modified.password || null}
            setModifiedPassword={(password) =>
              setModified({ ...modified, password })
            }
            setModifiedPasswordValid={(valid) => setPasswordValid(valid)}
          />
        </DialogContentSection>
        <DialogContentSection>
          <NotificationsInput
            notifications={settings?.notifications || null}
            modifiedNotifications={modified.notifications || null}
            setModifiedNotifications={(notifications) =>
              setModified({ ...modified, notifications })
            }
          />
        </DialogContentSection>
        <SubmitButton
          loading={loading}
          modified={_.values(modified).length > 0}
          valid={passwordValid || modified.password == null}
          onClick={handleSubmit}
        />
      </DialogContent>
    </Dialog>
  );
};

export const Input = ({
  label,
  children,
}: {
  label?: string;
  children: React.ReactNode;
}) => {
  const classes = useStyles();

  return (
    <div>
      {label ? (
        <Typography className={classes.label} variant="h3">
          {label}
        </Typography>
      ) : (
        <br />
      )}
      {children}
    </div>
  );
};

const EmailAddressInput = () => {
  const [email, setEmail] = useState<string>();

  const userQuery = useQuery<UserQuery>(USER_QUERY);
  useEffect(() => {
    setEmail(userQuery.data?.user?.email || "");
  }, [setEmail, userQuery]);

  return (
    <Input label="Email Address">
      <Tooltip title="Cannot change" placement="bottom">
        <TextField value={email || ""} variant="outlined" disabled />
      </Tooltip>
    </Input>
  );
};

const PasswordInput = ({
  modifiedPassword,
  setModifiedPassword,
  setModifiedPasswordValid,
}: {
  modifiedPassword: string | null;
  setModifiedPassword: (password: string) => void;
  setModifiedPasswordValid: (valid: boolean) => void;
}) => {
  const classes = useStyles();
  return (
    <Input label="Password">
      <TextField
        placeholder={"\u2022".repeat(8)}
        value={modifiedPassword || ""}
        onChange={(event) => setModifiedPassword(event.target.value)}
        modified={modifiedPassword != null}
        type="password"
        variant="outlined"
        showEditButton
      />
      <Fade in={modifiedPassword != null}>
        <PasswordCriteriaList
          className={clsx({
            [classes.criteriaListHidden]: modifiedPassword == null,
          })}
          newPassword={modifiedPassword || ""}
          setValid={(valid) =>
            modifiedPassword != null && setModifiedPasswordValid(valid)
          }
          spacing="dense"
        />
      </Fade>
    </Input>
  );
};

type NotificationEventType =
  | EventType.PROJECT_CREATED
  | EventType.PROJECT_COMPLETED;

const NotificationsInput = ({
  notifications,
  modifiedNotifications,
  setModifiedNotifications,
}: {
  notifications: SettingsQuery_settings_notifications | null;
  modifiedNotifications: Omit<
    SettingsQuery_settings_notifications,
    "__typename"
  > | null;
  setModifiedNotifications: (
    notifications: Omit<SettingsQuery_settings_notifications, "__typename">
  ) => void;
}) => {
  const determineEnabled = useCallback(
    (eventType: NotificationEventType) =>
      modifiedNotifications
        ? modifiedNotifications[eventType]
        : notifications?.[eventType] || false,
    [notifications, modifiedNotifications]
  );

  const updateEnabled = useCallback(
    (eventType: NotificationEventType, enabled: boolean) => {
      if (notifications) {
        setModifiedNotifications({
          ...(modifiedNotifications || _.omit(notifications, "__typename")),
          [eventType]: enabled,
        });
      }
    },
    [notifications, modifiedNotifications, setModifiedNotifications]
  );

  return (
    <Input label="Notifications">
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={determineEnabled(EventType.PROJECT_CREATED)}
              onChange={(event) =>
                updateEnabled(EventType.PROJECT_CREATED, event.target.checked)
              }
            />
          }
          disabled={notifications == null}
          label="Project created"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={determineEnabled(EventType.PROJECT_COMPLETED)}
              onChange={(event) =>
                updateEnabled(EventType.PROJECT_COMPLETED, event.target.checked)
              }
            />
          }
          disabled={notifications == null}
          label="Project completed"
        />
      </FormGroup>
    </Input>
  );
};

const SubmitButton = ({
  loading,
  modified,
  valid,
  onClick,
}: {
  loading: boolean;
  modified: boolean;
  valid: boolean;
  onClick: () => void;
}) => {
  const classes = useStyles();
  const analytics = useAnalytics();
  return (
    <div className={classes.submit}>
      <Button
        className={classes.button}
        color="primary"
        disabled={loading || !modified || !valid}
        primaryAction
        onClick={() => {
          analytics.event("Settings", "Click Save");
          onClick();
        }}
      >
        Save
      </Button>
    </div>
  );
};
