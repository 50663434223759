import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  makeStyles,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  disabled: {
    color: `${theme.palette.action.disabledBackground} !important`,
  },
}));

export interface CheckboxProps extends MuiCheckboxProps {}

export const Checkbox = ({ color = "primary", ...props }: CheckboxProps) => {
  return <MuiCheckbox classes={useStyles()} color={color} {...props} />;
};
