import React from "react";

import { Icon, IconProps } from "./Icon";

export interface LanguageIconProps extends IconProps {}

export const LanguageIcon = (props: LanguageIconProps) => {
  return (
    <Icon viewBox="0 0 640 512" {...props}>
      <path
        d="M168.1 236.2c-3.5-12.1-7.8-33.2-7.8-33.2h-.5s-4.3 21.1-7.8 33.2l-11.1 37.5H179zM0 120v272.12A23.94 23.94 0 0024 416h296V96H23.88A23.94 23.94 0 000 120zm74.62 216.19l57.65-168.14A12 12 0 01143.7 160h32.58a12.23 12.23 0 0111.43 8.05l57.64 168.14a11.7 11.7 0 01.65 3.93A12 12 0 01233.92 352H211a12 12 0 01-11.53-8.55L190 311.73h-60.34l-9.12 31.62A12.11 12.11 0 01109 352H86a12.07 12.07 0 01-11.43-15.81zM564 188h-64v-16a12 12 0 00-12-12h-16a12 12 0 00-12 12v16h-64a12 12 0 00-12 12v16a12 12 0 0012 12h114.3c-6.2 14.3-16.5 29-30 43.19a191 191 0 01-17.4-20.89 12.09 12.09 0 00-16-3.4l-7.3 4.3-6.5 3.89-.64.41a12 12 0 00-3.06 16.69 231.81 231.81 0 0021 25.69 284.34 284.34 0 01-26.1 18 12 12 0 00-4.2 16.2l7.9 13.89.2.34a12 12 0 0016.5 4 352.44 352.44 0 0035.4-24.89 348.11 348.11 0 0035.4 24.89 3.79 3.79 0 00.34.2 12 12 0 0016.36-4.5l7.9-14.01a12 12 0 00-4.1-16.2 310.64 310.64 0 01-26.1-18c21-22.49 35.8-46.28 42.7-69.88H564a12 12 0 0012-12V200a12 12 0 00-12-12z"
        opacity={0.4}
      />
      <path d="M616.12 96H320v320h296a23.94 23.94 0 0024-23.88V120a23.94 23.94 0 00-23.88-24zM576 216a12 12 0 01-12 12h-11.4c-6.9 23.6-21.7 47.39-42.7 69.88a310.64 310.64 0 0026.1 18 12 12 0 014.1 16.2l-7.9 13.89a12 12 0 01-16.36 4.5 3.79 3.79 0 01-.34-.2 348.11 348.11 0 01-35.4-24.89 352.44 352.44 0 01-35.4 24.89 12 12 0 01-16.5-4l-.2-.34-7.9-13.93a12 12 0 014.2-16.2 284.34 284.34 0 0026.1-18 231.81 231.81 0 01-21-25.69 12 12 0 013.06-16.69l.64-.41 6.5-3.89 7.3-4.3a12.09 12.09 0 0116 3.4 191 191 0 0017.4 20.89c13.5-14.2 23.8-28.89 30-43.19H396a12 12 0 01-12-12V200a12 12 0 0112-12h64v-16a12 12 0 0112-12h16a12 12 0 0112 12v16h64a12 12 0 0112 12zm-388.29-47.95a12.23 12.23 0 00-11.43-8.05H143.7a12 12 0 00-11.43 8.05L74.62 336.19A12.07 12.07 0 0086.05 352h23a12.11 12.11 0 0011.53-8.65l9.12-31.62H190l9.42 31.72A12 12 0 00211 352h23a12 12 0 0012-11.88 11.7 11.7 0 00-.65-3.93zM140.9 273.7l11.1-37.5c3.5-12.1 7.8-33.2 7.8-33.2h.5s4.3 21.1 7.8 33.2l10.9 37.5z" />
    </Icon>
  );
};
