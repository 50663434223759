import { Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

import { ButtonProps } from "../Button";
import { Paper, PaperProps } from "../Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  scrollable: {
    overflowY: "auto",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  headerText: {
    fontSize: "20px",
  },
  action: {
    fontSize: "12px",
    padding: "2px 12px",
    marginTop: "-8px",
  },
  iconSizeSmall: {
    "& *:first-child": {
      fontSize: "12px !important",
    },
  },
  paper: {
    flexGrow: 1,
  },
}));

export interface CardProps extends PaperProps {
  title: string;
  renderAction?: (props: ButtonProps) => JSX.Element;
  disableScroll?: boolean;
  column: 1 | 2 | 3;
  row: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  rowSpan: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

export const Card = ({
  title,
  renderAction,
  disableScroll = false,
  column,
  row,
  rowSpan = 1,
  ...props
}: CardProps) => {
  const classes = useStyles();
  return (
    <div
      className={classes.root}
      style={{
        gridColumn: column,
        gridRowStart: row,
        gridRowEnd: row + rowSpan,
      }}
    >
      <div className={classes.header}>
        <Typography
          className={classes.headerText}
          variant="caption"
          gutterBottom
        >
          {title}
        </Typography>
        {renderAction &&
          renderAction({
            className: classes.action,
            classes: { iconSizeSmall: classes.iconSizeSmall },
            color: "primary",
            size: "small",
          })}
      </div>
      <Paper
        className={clsx(classes.paper, {
          [classes.scrollable]: !disableScroll,
        })}
        {...props}
      />
    </div>
  );
};
