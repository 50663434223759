import React from "react";
import { useRouteMatch } from "react-router";

import { DialogProps } from "../../Dialog";
import { InviteClientDialog } from "./invite";
import { ManageClientsDialog } from "./manage";

export interface ClientsAdminDialogProps extends DialogProps {}

export const ClientsAdminDialog = ({
  open,
  ...props
}: ClientsAdminDialogProps) => {
  const listClientsRoute = useRouteMatch({
    path: "/admin/clients",
    exact: true,
  });
  const inviteClientRoute = useRouteMatch("/admin/clients/invite");

  return (
    <React.Fragment>
      <ManageClientsDialog open={open && listClientsRoute != null} {...props} />
      <InviteClientDialog open={open && inviteClientRoute != null} {...props} />
    </React.Fragment>
  );
};
